import { Toast } from '@capacitor/toast';
import { BarcodeScanner, BarcodeScannerOptions } from '@ionic-native/barcode-scanner';
import { IonAlert, IonButton, IonButtons, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonLoading, IonModal, IonNote, IonPage, IonPopover, IonRow, IonTextarea, IonTitle, IonToolbar, isPlatform, useIonAlert, useIonToast, useIonViewDidEnter } from '@ionic/react';
import { BrowserMultiFormatReader } from '@zxing/library';
import { addCircle, alertCircle, appsOutline, arrowBack, cardOutline, clipboardOutline, closeOutline, cubeOutline, ellipsisVertical, eye, flag, flagOutline, hourglassOutline, informationCircleOutline, listOutline, locationOutline, navigateOutline, notificationsOutline, personOutline, phonePortraitOutline, powerOutline, receiptOutline, starOutline, swapHorizontalOutline, timeOutline, trash } from 'ionicons/icons';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { encerrarOperacao, incluiNotaFiscal, operacaoRegistrarAnotacao, removerAnotacaoOperacao, removerFotoOperacao, requestService, urlBase } from '../../../../../Utils/Services';
import { usePhotoGallery } from '../../../../../components/usePhotoGallery';
import { MessageContext } from '../../../../../contexts/MessageContext';
import Formulario from '../Formulario/Formulario';
import "./Acompanhar.css";
import iconHorario from "./imgs/horario.svg";
import maps from './imgs/maps.png';
import iconMoto from "./imgs/moto.svg";
import iconTempo from "./imgs/tempo.svg";
import waze from './imgs/waze.png';

type Props = {
  abrirMenu: boolean;
  estabelecimento: string;
  codigoBarras: string;
  operacaoid: string;
  esconderProjeto?: boolean;
  onDismiss: () => void;
};

let stream2: any;
let arrayDevices: any[] = []

const Acompanhar: React.FC<Props> = (props: Props) => {

  const inputRef = useRef<any>(null);

  const [idOperacao, setIdOperacao] = useState("");
  const { photos, takePhoto, loadPhotos } = usePhotoGallery(props.operacaoid);
  const history = useHistory<any>();
  const location = useLocation<any>();
  const [showBrowserCamera, setShowBrowserCamera] = useState(false);
  const [codigoBarras, setCodigoBarras] = React.useState<string>();
  const [dadosOperacao, setDadosOperacao] = useState<any>([]);
  const [horaInicio, setHoraInicio] = useState(new Date());
  const [horaFim, setHoraFim] = useState(new Date());
  const [temHoraFim, setTemHoraFim] = useState(false);
  const [tempoOperacao, setTempoOperacao] = useState("...");
  const [hideButton, setHideButton] = useState(true);
  const [showActionSheet, setShowActionSheet] = useState(false);
  const [showActionSheetFormulario, setShowActionSheetFormulario] = useState(false);
  const [alertConfirmar, setAlertConfirmar] = useState(false);
  const [notasAdicionais, setNotasAdicionais] = useState<any>([]);
  const [codeReader, setCodeReader] = React.useState(new BrowserMultiFormatReader());
  const [showCarregando, setShowCarregando] = useState(false);
  const [showModalCodigoBarrasDigitar, setShowModalCodigoBarrasDigitar] = useState(false);
  const [front, setFront] = useState(false);
  const [streamData, setStreamData] = useState<MediaStream>();
  const [alertaPreenchimento, setAlertaPreenchimento] = useState(false);
  const [botaoAtivo, setBotaoAtivo] = useState(true);
  const [showLoadingPage, setShowLoadingPage] = useState(false);
  const [mostartAlerta] = useIonAlert();
  const [mostrarToast, fecharToast] = useIonToast();
  const [abrirFormulario, setAbrirFormulario] = useState(false);
  const [acessos] = useState<any>(JSON.parse(window.localStorage.getItem("acessos") || '{}'));
  const { recarregarOperacao, setRecarregarOperacao } = useContext(MessageContext);
  const { esconderMenu, setEsconderMenu } = useContext(MessageContext);
  const { nomeMenu, setNomeMenu } = useContext(MessageContext);

  const [showPopover, setShowPopover] = useState(false);
  const [popoverEvent, setPopoverEvent] = useState(null);

  const openPopover = (e: any) => {
    e.persist();
    setPopoverEvent(e);
    setShowPopover(true);
  };

  const closePopover = () => {
    setShowPopover(false);
    setPopoverEvent(null);
  };

  useEffect(() => {

    console.log("idOperacao", idOperacao);

    if (idOperacao != "") {
      getOperacao();
    }
  }, [idOperacao]);


  useIonViewDidEnter(() => {
    setEsconderMenu(true);
    setNomeMenu("");
    if (params.operacaoid) {
      setIdOperacao(params.operacaoid);
    }
  });

  let params: any = {
    codigoBarras: "",
    estabelecimento: "",
    operacaoid: "",
    esconderProjeto: false,
  }
  params = useParams();

  if (location && location.state && location.state.operacaoid) {
    params.operacaoid = location.state.operacaoid;
    params.esconderProjeto = location.state.esconderProjeto;
  }


  if (props.operacaoid) {
    params = {
      codigoBarras: props.codigoBarras,
      estabelecimento: props.estabelecimento,
      operacaoid: props.operacaoid,
      esconderProjeto: props.esconderProjeto,

    }
  }

  var estabelecimento = {
    nome: params.estabelecimento,
    parceiro: false,
    endereco: "Rua Selvin Ln, 1035, São Paulo, SP",
    horarios: [
      { dia: "segunda-feira", inicio: "8:00", fim: "16:00", aberto: true },
      { dia: "terça-feira", inicio: "8:00", fim: "16:00", aberto: true },
      { dia: "quarta-feira", inicio: "8:00", fim: "16:00", aberto: true },
      { dia: "quinta-feira", inicio: "8:00", fim: "16:00", aberto: true },
      { dia: "sexta-feira", inicio: "8:00", fim: "16:00", aberto: true },
      { dia: "sábado", inicio: "8:00", fim: "16:00", aberto: true },
      { dia: "domingo", inicio: "8:00", fim: "16:00", aberto: false },
    ],
    medias: {
      historico: "45 min",
      hoje: "1h 30min"
    }
  }

  function tempoCorrido(horario: Date, dataFim?: Date) {
    var g = Math.ceil(((dataFim ? dataFim.getTime() : Date.now()) - horario.getTime()) / 1000 / 60);
    var tempoApurado = Math.floor(g / 60).toString() + 'h' + ('0' + (g % 60).toString()).substr(-2) + 'min';
    return tempoApurado !== "0h00min" ? tempoApurado : "0h01min";
  }

  const getOperacao = async () => {
    var token: string = "";
    var tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") { token = tkn; }
    console.log("operacaoid", params.operacaoid);

    setShowLoadingPage(true);
    const resp = await fetch(urlBase.url + "/operacoes/" + params.operacaoid,
      {
        method: urlBase.method,
        headers: [
          ["token", token]
        ],
        credentials: 'include'
      });
    const operacao = await resp.json();
    if (operacao.Item !== undefined) {
      setDadosOperacao(operacao.Item);
      setHideButton(operacao.Item.sk.startsWith("usuarios#") ? false : false);
      setHoraInicio(new Date(operacao.Item.dataInicio));
      setTemHoraFim(operacao.Item.dataEncerramento ? true : false);
      setHoraFim(operacao.Item.dataEncerramento ? new Date(operacao.Item.dataEncerramento) : new Date());
      setTempoOperacao(tempoCorrido(new Date(operacao.Item.dataInicio), operacao.Item.dataEncerramento ? new Date(operacao.Item.dataEncerramento) : new Date()));
      setNotasAdicionais(operacao.Item.notasAdicionais || []);

      if (operacao.Item.photo) {


        let _photos: string[] = operacao.Item.photo;
        loadPhotos(_photos);

      }

    }
    setShowLoadingPage(false);
  }

  setInterval(() => setTempoOperacao(tempoCorrido(horaInicio)), 60000);

  function loadHorarios() {
    return estabelecimento.horarios.map((value, index) => {
      return (
        <div className="horario" key={index}>
          <IonLabel>{value.dia}</IonLabel>
          <IonLabel>{value.aberto ? value.inicio + " - " + value.fim : "Fechado"}</IonLabel>
        </div>);
    });

  }

  function encerrar2() {
    var token: string = "";
    var tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") { token = tkn; }

    return fetch(encerrarOperacao.url.replace("{id}", params.operacaoid),
      {
        method: encerrarOperacao.method,
        headers: [
          ["token", token]
        ],
        credentials: 'include'
      });
  }


  function adicionaZeroNaData(numero: any) {
    if (numero <= 9)
      return "0" + numero;
    else
      return numero;
  }

  const openScanner = async () => {
    if (isPlatform("mobileweb") || isPlatform("desktop")) {
      setShowBrowserCamera(true);
    }
    else {

      const opt: BarcodeScannerOptions = {
        formats: 'CODE_128',
        prompt: 'Escaneie o codigo de barras'
      }

      const data: any = await BarcodeScanner.scan(opt);

      if (data !== null) {
        registrarNotaFiscal(data.text);
      }
    }
  };

  function registrarNotaFiscal(valor: any) {

    if (valor === undefined || valor.replace(' ', '') === '') {
      setAlertaPreenchimento(true);
    }
    else {
      setShowCarregando(true);
      let objeto = { "notaFiscal": valor.toString().replace(/ /g, ""), "tipoOperacao": "Indefinida" };

      const registrar = () => {
        requestService(incluiNotaFiscal.url.replace("{id}", params.operacaoid.replace(/"#"/g, "%23")), {
          method: incluiNotaFiscal.method,
          headers: [
            ["token", window.localStorage.getItem("token")]
          ],
          body: JSON.stringify(objeto),
          credentials: 'include'
        },
          history,
          (response: any) => {
            let objeto = { "notaFiscal": valor.toString(), "tipoOperacao": "Indefinida" };
            let incremento = notasAdicionais;
            incremento.push(objeto);
            setNotasAdicionais(incremento);
            Toast.show({
              text: "Sucesso na inclusão da nota",
              position: "center",
              duration: "long"
            });
            setShowBrowserCamera(false);
            setShowModalCodigoBarrasDigitar(false);
            setCodigoBarras('');
            setShowCarregando(false);
          },
          (error: any) => {
            Toast.show({
              text: "Erro na inclusão da nota",
              position: "center",
              duration: "long"
            });
            setShowBrowserCamera(false);
            setShowCarregando(false);
          });
      }

      registrar();
    }
  }

  function cancelarScan() {
    if (codeReader) {
      codeReader.stopAsyncDecode();
      codeReader.stopContinuousDecode();
    }

    console.log(streamData);
    if (streamData) {
      streamData.getVideoTracks().forEach((valor: MediaStreamTrack) => {
        console.log(valor);
        valor.stop();
      });
    }
  }

  function startCamera() {
    if (arrayDevices.length == 0) {
      console.log("loadDevices");
      navigator.mediaDevices
        .enumerateDevices()
        .then(gotDevices)
        .then(getStream)
        .catch((err) => { console.log(err); });
    } else {
      getStream();
    }
  }

  function getStream() {
    if (stream2) {
      stream2.getTracks().forEach(function (track: any) {
        console.log("getTracks");
        track.stop();
      });
    }

    const constraints = {
      audio: false,
      video: {
        width: 1024,
        height: 576,
        facingMode: (front ? "user" : "environment")
      },
    };

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(gotStream)
      .catch((err) => { console.log(err) });
  }

  function gotDevices(deviceInfos: any) {
    for (let i = 0; i !== deviceInfos.length; ++i) {
      const deviceInfo = deviceInfos[i];
      if (deviceInfo.kind === "videoinput") {
        arrayDevices.push(deviceInfo.deviceId);
      }
    }
  }

  function gotStream(stream: MediaStream) {
    setStreamData(stream);
    const videoElement = document.getElementById("video") as HTMLVideoElement;

    videoElement.srcObject = stream;
    codeReader.decodeFromStream(stream, videoElement, (result: any) => {
      if (result) {
        codeReader.stopAsyncDecode();
        codeReader.stopContinuousDecode();
        stream.getVideoTracks().forEach((valor: MediaStreamTrack) => {
          valor.stop();
        });
        registrarNotaFiscal(result.text.toString());
      } else {

      }
    }).catch((err) => { console.log(err) });

  }

  function space(str: any, after: any) {
    setAlertaPreenchimento(false);

    if (!str) {
      setCodigoBarras('');
      return false;
    }
    after = after || 4;
    var v = str.replace(/[^\dA-Z]/g, ''),
      reg = new RegExp(".{" + after + "}", "g");
    setCodigoBarras(
      v.replace(reg, function (a: any) {
        return a + ' ';
      })
    );
  };

  function cpf_mask(v: string) {
    v = v.replace(/\D/g, ''); //Remove tudo o que não é dígito
    v = v.replace(/(\d{3})(\d)/, '$1.$2'); //Coloca um ponto entre o terceiro e o quarto dígitos
    v = v.replace(/(\d{3})(\d)/, '$1.$2'); //Coloca um ponto entre o terceiro e o quarto dígitos
    //de novo (para o segundo bloco de números)
    v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2'); //Coloca um hífen entre o terceiro e o quarto dígitos
    return v;
  }

  function formataTelefone(numero: any) {
    if (numero?.length == 13) {
      let BRNumber = numero.replace("55", "").match(/(\d{2})(\d{5})(\d{4})/);
      BRNumber = "(" + BRNumber[1] + ") " + BRNumber[2] + "-" + BRNumber[3];
      return BRNumber;
    }
    else if (numero?.length == 12) {
      let BRNumber = numero.replace("55", "").match(/(\d{2})(\d{4})(\d{4})/);
      BRNumber = "(" + BRNumber[1] + ") " + BRNumber[2] + "-" + BRNumber[3];
      return BRNumber;
    }
    else { return "" };
  }

  async function funcRemoverLoja(pk: string, data: string) {

    let resp = await fetch(removerAnotacaoOperacao.url.replace("{id}", pk.replace(/#/g, "%23")).replace("{anotacao}", data.replace(/#/g, "%23")),
      {
        method: removerAnotacaoOperacao.method,
        headers: [
          ["token", localStorage.getItem("token") || ""]
        ],
        credentials: 'include'
      });

    let retorno = await resp.json();

    console.log("retorno", retorno);
    if (resp.status === 400 || resp.status === 401) {
      //history.replace("/login/");
    }
    else {
      console.log("dadosProjeto.anotacoes", dadosOperacao.anotacoes);

      var res = dadosOperacao.anotacoes.filter((p: any, i: any) => {
        if (p.data == data) {
          dadosOperacao.anotacoes.splice(i, 1); //remove the mached object from the original array
          return p;
        }
      });
      console.log("res", res);
    }
    return resp;
  }

  const loadAnotacoes = () => {

    return dadosOperacao.anotacoes?.sort(function (a: any, b: any) {
      if (a.data < b.data) {
        return 1;
      }
      if (a.data > b.data) {
        return -1;
      }
      return 0;
    }).slice(0).map((value: any) => (

      <IonItem lines="none">

        <IonLabel className="ion-text-wrap">
          <h3 style={{ "verticalAlign": "middle" }}><strong>{new Date(value.data).toLocaleDateString() + " " + new Date(value.data).toLocaleTimeString()}</strong></h3>
          <h3>{value.anotacao + (value.pessoa ? (' - ' + value.pessoa) : "")}</h3>
        </IonLabel>
        <IonIcon hidden={acessos?.operacoes != 'edicao'} icon={trash} color="danger" style={{ fontSize: "20px" }} onClick={() =>
          mostartAlerta({
            backdropDismiss: false,
            header: 'Remover Anotação',
            message: 'Realizar <strong>remoção</strong>?',
            buttons: [
              {
                text: 'Cancelar',
                role: 'cancel',
              },
              {
                text: 'Remover',
                cssClass: 'primary',
                handler: () => {
                  setShowLoadingPage(true);
                  let retorno = funcRemoverLoja(dadosOperacao.pk, value.data);
                  if (retorno) {
                    retorno?.then(res => {
                      if (res.status === 200) {
                        console.log("res", res)
                        mostrarToast({
                          duration: 2000,
                          position: 'middle',
                          buttons: [{ text: 'ok', handler: () => fecharToast() }],
                          message: 'Anotação removida com sucesso.'
                        });
                      }
                      else {
                        mostrarToast({
                          position: 'middle',
                          buttons: [{ text: 'ok', handler: () => fecharToast() }],
                          message: 'Erro ao tentar remover.'
                        });
                      }
                    }).catch(() => {
                      mostrarToast({
                        position: 'middle',
                        buttons: [{ text: 'ok', handler: () => fecharToast() }],
                        message: 'Erro ao tentar remover.'
                      });
                    }).finally(() => {
                      setShowLoadingPage(false);
                    });
                  }
                  else {
                    mostrarToast({
                      position: 'middle',
                      buttons: [{ text: 'ok', handler: () => fecharToast() }],
                      message: 'Erro ao tentar remover.'
                    });
                    setShowLoadingPage(false);
                  }
                  return true;
                }
              }
            ],
            onDidDismiss: (e) => console.log('did dismiss'),
          })
        }>
        </IonIcon>
      </IonItem>
    ))
  }


  function deletarFoto(url: any) {

    console.log(params.operacaoid, url);

    var token: string = "";
    var tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") { token = tkn; }
    setShowLoadingPage(true);
    const delFoto = async () => {
      const resp = await fetch(removerFotoOperacao.url.replace("{id}", params.operacaoid.replace(/#/g, "%23")),
        {
          method: removerFotoOperacao.method,
          headers: [
            ["token", token]
          ],
          body: JSON.stringify({ 'photo': url }),
          credentials: 'include'
        });
      const retorno = await resp.json();
      console.log("retorno", retorno);

      if (retorno.Item) {


        let _photos: string[] = retorno.Item;
        loadPhotos(_photos);

      }
      else {
        loadPhotos([]);
      }
      setShowLoadingPage(false);
    }

    delFoto();


  }

  function getButtons() {

    let resultado: any = [];

    if (acessos?.operacoes == 'edicao' || acessos?.operacoes == 'leitura') {


      resultado.push(
        {
          text: 'Relatório',
          disabled: false,
          icon: clipboardOutline,
          handler: () => {
            setAbrirFormulario(true);
          }
        }
      );
    }

    if (acessos?.operacoes == 'edicao') {


      resultado.push(
        {
          text: 'Encerrar Operação',
          disabled: dadosOperacao.status === "Em andamento" ? false : true,
          icon: powerOutline,
          handler: () => {
            setAlertConfirmar(true);
          }
        }
      );
    }


    resultado.push(
      {
        text: 'Cancelar',
        disabled: false,
        icon: closeOutline,
        role: 'cancel',
        handler: () => {
          console.log('Cancel clicked');
        }
      });


    return resultado;
  }

  function carregarDetalhesProjeto(projetoid: any, empresaid: any, abrirInserirAgendamento: boolean) {
    history.push("../projetos/projetosdetalhes", { projetoid: projetoid, empresaid: empresaid, abrirInserirAgendamento: abrirInserirAgendamento, esconderOperacoes: true })
  }

  function registrarAnotacao(pk: any, mensagem: any) {
    if (!mensagem || mensagem.trim() == "") {
      return null;
    }

    let params = {
      mensagem: mensagem
    }
    return fetch(operacaoRegistrarAnotacao.url.replaceAll("{pk}", pk.replaceAll(/#/g, "%23")),
      {
        method: operacaoRegistrarAnotacao.method,
        headers: [
          ["token", localStorage.getItem("token") || ""]
        ],
        body: JSON.stringify(params),
        credentials: 'include'
      });
  }

  async function closeModal() {
    console.log("fechou o formulario");
    setAbrirFormulario(false);

  }

  async function encerrarAction() {
    //document.getElementsByTagName("ion-tab-bar")[0].style.display = "flex";
    setAbrirFormulario(false);
  }


  return (

    <IonPage className="Acompanhar">
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={() => { history.goBack();/*.replace("/empresa/operacoes", { recarregar: false }); */ }}>
              <IonIcon slot="icon-only" icon={arrowBack} />
            </IonButton>
          </IonButtons>
          <IonTitle color="dark" className="ion-text-wrap" style={{ "padding-left": "20px", "padding-top": "0px", "padding-bottom": "0px", "text-align": "left" }}>
            {dadosOperacao ? (window.localStorage.getItem("servicoTipo") || "Projeto") + " - " + dadosOperacao.descricaoAgendamento : ""}
          </IonTitle>

          <IonButtons slot="end">
            <IonButton onClick={openPopover}>
              <IonIcon slot="icon-only" icon={ellipsisVertical} />
            </IonButton>
          </IonButtons>
          <IonPopover
            isOpen={showPopover}
            event={popoverEvent}
            onDidDismiss={closePopover}
          >
            <IonList>
              {getButtons().map((button: any, index: number) => (
                <IonItem key={index} lines="none" disabled={button.disabled} button onClick={() => {
                  if (button.handler) button.handler();
                  closePopover();
                }}>
                  <IonIcon icon={button.icon} slot="start" />
                  <IonLabel>{button.text}</IonLabel>
                </IonItem>
              ))}
            </IonList>
          </IonPopover>

        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen={false}>
        <div className="header">
          <div className="info">
            <IonIcon src={flagOutline}></IonIcon>
            <div>
              <IonLabel>Início</IonLabel>
              <IonLabel>{dadosOperacao && dadosOperacao.dataInicio ? ("0" + new Date(dadosOperacao.dataInicio).getHours().toString()).substr(-2) + ":" + ("0" +
                new Date(dadosOperacao.dataInicio).getMinutes().toString()).substr(-2) : ""}</IonLabel>
            </div>
          </div>
          <div className="info">
            <IonIcon src={!temHoraFim ? hourglassOutline : flag}></IonIcon>
            <div hidden={temHoraFim}>
              <IonLabel>Em andamento</IonLabel>
              <IonLabel>{tempoOperacao}</IonLabel>
            </div>
            <div hidden={!temHoraFim}>
              <IonLabel>Término</IonLabel>
              <IonLabel>{dadosOperacao && dadosOperacao.dataEncerramento ? ("0" + new Date(dadosOperacao.dataEncerramento).getHours().toString()).substr(-2) + ":" + ("0" +
                new Date(dadosOperacao.dataEncerramento).getMinutes().toString()).substr(-2) : ""}</IonLabel>
            </div>
          </div>
        </div>
        <IonList>
          <IonListHeader lines="full">
            <IonLabel>Operação</IonLabel>
          </IonListHeader>


          <IonItem lines="none">
            <IonIcon icon={cubeOutline} slot="start" />
            <div className="contentList">
              <h1>Descrição</h1>
              <h2>{dadosOperacao && dadosOperacao.descricaoAgendamento ? dadosOperacao.descricaoAgendamento : "Sem descrição"}</h2>

            </div>
          </IonItem>

          <IonItem lines="none">
            <IonIcon icon={swapHorizontalOutline} slot="start" />
            <div className="contentList">
              <h1>Tipo</h1>
              <h2>{dadosOperacao && dadosOperacao.tipoOperacao ? dadosOperacao.tipoOperacao : ""}</h2>

            </div>
          </IonItem>
          <IonItem lines="none">
            <IonIcon icon={informationCircleOutline} slot="start" />
            <div className="contentList">
              <h1>Status</h1>
              <h2>{dadosOperacao ? dadosOperacao.status : ""}</h2>
            </div>
          </IonItem>



          <IonItem lines="none">
            <IonIcon icon={locationOutline} slot="start" />
            <div className="contentList">
              <h1>Endereço</h1>
              <h2>{dadosOperacao && dadosOperacao.endereco ? dadosOperacao.endereco : ""}</h2>
            </div>
          </IonItem>

          <IonItem lines="none">
            <IonIcon src={navigateOutline} slot="start" />
            <div className="contentList">
              {(dadosOperacao && dadosOperacao.enderecoLat && dadosOperacao.enderecoLng) ? (
                <div style={{ paddingTop: "10px", display: "flex", columnGap: "20px", justifyContent: "left" }} >
                  <a target='_blank' href={"https://www.google.com/maps/search/?api=1&query=" + (dadosOperacao ? dadosOperacao.enderecoLat + "," + dadosOperacao.enderecoLng + "&query_place_id=" : "")}><img src={maps} alt="maps" /></a>
                  <a target='_blank' href={"https://waze.com/ul?ll=" + (dadosOperacao ? dadosOperacao.enderecoLat + "%2C" + dadosOperacao.enderecoLng : "") + "&navigate=yes"}><img src={waze} alt="waze" /></a>
                </div>
              ) : (
                <h2 style={{ color: "red" }}><b>Sem Localização</b></h2>
              )}
            </div>
          </IonItem>

          <IonItem lines="none">
            <IonIcon icon={timeOutline} slot="start" />
            <div className="contentList">
              <h1>Duração</h1>
              <h2>{tempoOperacao ? tempoOperacao : ""}</h2>
            </div>
          </IonItem>

          <IonListHeader lines="full">
            <IonLabel>Projeto</IonLabel>
          </IonListHeader>

          <IonItem disabled={params.esconderProjeto} lines="none" detail onClick={(e) => {

            carregarDetalhesProjeto(dadosOperacao.projetoid.replaceAll(/#/g, '%23'), dadosOperacao.gsi2pk.replaceAll(/#/g, '%23'), false);
          }}>
            <IonIcon icon={appsOutline} slot="start" />
            <div className="contentList">
              <h1>Nome</h1>
              <h2>{dadosOperacao && dadosOperacao.nomeProjeto ? dadosOperacao.nomeProjeto : "Sem descrição"}</h2>
            </div>
          </IonItem>


          <IonListHeader lines="full">
            <IonLabel>Empresa</IonLabel>
          </IonListHeader>
          <IonItem lines="none">
            <IonIcon src={personOutline} slot="start" />
            <div className="contentList">
              <h1>Nome</h1>
              <h2>{dadosOperacao && dadosOperacao.empresaNome ? dadosOperacao.empresaNome : ""}</h2>
            </div>
          </IonItem>
          <IonItem lines="none">
            <IonIcon src={phonePortraitOutline} slot="start" />
            <div className="contentList">
              <h1>Telefone</h1>
              <div>
                <a target='_blank' href={"https://api.whatsapp.com/send?phone=" + (dadosOperacao ? dadosOperacao.empresaTelefone : "")}><h2>{dadosOperacao && dadosOperacao.empresaTelefone ? formataTelefone(dadosOperacao.empresaTelefone) : ""}</h2></a>
              </div>
            </div>
          </IonItem>


          <IonListHeader lines="full">
            <IonLabel>Cliente</IonLabel>
          </IonListHeader>
          <IonItem lines="none">
            <IonIcon src={personOutline} slot="start" />
            <div className="contentList">
              <h1>Nome</h1>
              <h2>{dadosOperacao && dadosOperacao.clienteNome ? dadosOperacao.clienteNome : ""}</h2>
            </div>
          </IonItem>
          {/* <IonItem lines="none">
            <IonIcon src={card} slot="start" />
            <div className="contentList">
              <h1>CPF</h1>
              <h2>{dadosOperacao && dadosOperacao.clienteCpf ? cpf_mask(dadosOperacao.clienteCpf) : ""}</h2>
            </div>
          </IonItem> */}
          <IonItem lines="none">
            <IonIcon src={phonePortraitOutline} slot="start" />
            <div className="contentList">
              <h1>Telefone</h1>
              <div style={{ paddingTop: "10px", display: "flex", columnGap: "20px", justifyContent: "left" }} >
                <a target='_blank' href={"https://api.whatsapp.com/send?phone=" + (dadosOperacao ? dadosOperacao.clienteTelefone : "")}><h2>{dadosOperacao && dadosOperacao.clienteTelefone ? formataTelefone(dadosOperacao.clienteTelefone) : ""}</h2></a>
              </div>
            </div>
          </IonItem>

          <IonItem lines="none">
            <IonIcon src={notificationsOutline} slot="start" />
            <div className="contentList">
              <h1>Enviar notificação?</h1>
              <h2>{dadosOperacao && dadosOperacao.enviarMsgCliente ? dadosOperacao.enviarMsgCliente : ""}</h2>
            </div>
          </IonItem>

          <IonItem lines="none">
            <IonIcon icon={starOutline} slot="start" />
            <div className="contentList">
              <h1>Avaliação</h1>
              <h2>{dadosOperacao && dadosOperacao.avaliacaoCliente ? dadosOperacao.avaliacaoCliente : "Não avaliado"}</h2>
            </div>
          </IonItem>

          <IonItem lines="none">
            <IonIcon icon={informationCircleOutline} slot="start" />
            <div className="contentList">
              <h1>Comentário</h1>
              <IonTextarea readonly={true} style={{ "--background": "#eeeeee" }} rows={6} value={dadosOperacao && dadosOperacao.comentarioCliente ? dadosOperacao.comentarioCliente : "Não avaliado"}></IonTextarea>
            </div>
          </IonItem>

          <IonListHeader lines="full">
            <IonLabel>Entregador</IonLabel>
          </IonListHeader>
          <IonItem lines="none">
            <IonIcon src={personOutline} slot="start" />
            <div className="contentList">
              <h1>Nome</h1>
              <h2>{dadosOperacao && dadosOperacao.motoristaNome ? dadosOperacao.motoristaNome : ""}</h2>
            </div>
          </IonItem>

          <IonItem lines="none">
            <IonIcon src={cardOutline} slot="start" />
            <div className="contentList">
              <h1>CPF</h1>
              <h2>{dadosOperacao && dadosOperacao.motoristaDocumento ? cpf_mask(dadosOperacao.motoristaDocumento) : ""}</h2>
            </div>
          </IonItem>

          <IonItem lines="none">
            <IonIcon src={phonePortraitOutline} slot="start" />
            <div className="contentList">
              <h1>Telefone</h1>
              <div>
                <div style={{ paddingTop: "10px", display: "flex", columnGap: "20px", justifyContent: "left" }} >
                  <a target='_blank' href={"https://api.whatsapp.com/send?phone=" + (dadosOperacao ? dadosOperacao.motoristaTelefone : "")}><h2>{dadosOperacao && dadosOperacao.motoristaTelefone ? formataTelefone(dadosOperacao.motoristaTelefone) : ""}</h2></a>
                </div>
              </div>
            </div>
          </IonItem>

          <IonItem lines="none">
            <IonIcon src={iconMoto} slot="start" />
            <div className="contentList">
              <h1>Veículo</h1>
              <h2>{dadosOperacao && dadosOperacao.veiculo ? dadosOperacao.veiculo : ""}</h2>
            </div>
          </IonItem>
          <IonItem lines="none" hidden={!(dadosOperacao && dadosOperacao.dataLocalizacao)}>
            <IonIcon src={navigateOutline} slot="start" />
            <div className="contentList">
              <h1>Data ultima localização</h1>
              <h2>{dadosOperacao && dadosOperacao.dataLocalizacao ? new Date(dadosOperacao.dataLocalizacao).toLocaleString('pt-BR').replace(',', '') : ""}</h2>
              <div style={{ paddingTop: "10px", display: "flex", columnGap: "20px", justifyContent: "left" }} >
                <a target='_blank' href={"https://www.google.com/maps/search/?api=1&query=" + (dadosOperacao && dadosOperacao.enderecoPrestadorLat ? dadosOperacao.enderecoPrestadorLat.concat(",").concat(dadosOperacao.enderecoPrestadorLng).concat("&query_place_id=") : "")}><img src={maps} alt="maps" /></a>
                <a target='_blank' href={"https://waze.com/ul?ll=" + (dadosOperacao && dadosOperacao.enderecoPrestadorLat ? dadosOperacao.enderecoPrestadorLat.concat("%2C").concat(dadosOperacao.enderecoPrestadorLng) : "") + "&navigate=yes"}><img src={waze} alt="waze" /></a>
              </div>
            </div>
          </IonItem>

          <IonItem lines="none">
            <IonIcon src={listOutline} slot="start" />
            <div className="contentList">
              <h1>Atividades</h1>
              <IonTextarea readonly={true} style={{ "--background": "#eeeeee" }} rows={6} value={dadosOperacao && dadosOperacao.atividadesPrestador ? dadosOperacao.atividadesPrestador : "Não informado"}></IonTextarea>
            </div>
          </IonItem>

          <IonItem lines="none" hidden={!temHoraFim}>
            <IonIcon icon={starOutline} slot="start" />
            <div className="contentList">
              <h1>Avaliação</h1>
              <h2>{dadosOperacao && dadosOperacao.avaliacao ? dadosOperacao.avaliacao : "Não avaliado"}</h2>
            </div>
          </IonItem>

          <IonItem lines="none" hidden={!temHoraFim}>
            <IonIcon icon={informationCircleOutline} slot="start" />
            <div className="contentList">
              <h1>Relatório</h1>
              <IonTextarea readonly={true} style={{ "--background": "#eeeeee" }} rows={6} value={dadosOperacao && dadosOperacao.comentario ? dadosOperacao.comentario : "Não avaliado"}></IonTextarea>
            </div>
          </IonItem>

          <IonListHeader lines="full" hidden={!(dadosOperacao && dadosOperacao.responsavelNome)}>
            <IonLabel>Arquiteto</IonLabel>
          </IonListHeader>
          <IonItem lines="none" hidden={!(dadosOperacao && dadosOperacao.responsavelNome)}>
            <IonIcon src={personOutline} slot="start" />
            <div className="contentList">
              <h1>Nome</h1>
              <h2>{dadosOperacao && dadosOperacao.responsavelNome ? dadosOperacao.responsavelNome : ""}</h2>
            </div>
          </IonItem>

          <IonItem lines="none" hidden={!(dadosOperacao && dadosOperacao.responsavelNome)}>
            <IonIcon src={phonePortraitOutline} slot="start" />
            <div className="contentList">
              <h1>Telefone</h1>
              <div style={{ paddingTop: "10px", display: "flex", columnGap: "20px", justifyContent: "left" }} >
                <a target='_blank' href={"https://api.whatsapp.com/send?phone=" + (dadosOperacao ? dadosOperacao.responsavelTelefone : "")}><h2>{dadosOperacao && dadosOperacao.responsavelTelefone ? formataTelefone(dadosOperacao.responsavelTelefone) : ""}</h2></a>
              </div>

            </div>
          </IonItem>
          <IonItem lines="none" hidden={!(dadosOperacao && dadosOperacao.responsavelNome)}>
            <IonIcon src={notificationsOutline} slot="start" />
            <div className="contentList">
              <h1>Enviar notificação?</h1>
              <h2>{dadosOperacao && dadosOperacao.enviarMsgResposavel ? dadosOperacao.enviarMsgResposavel : ""}</h2>
            </div>
          </IonItem>


          <IonItem lines="full" hidden={true}>
            <IonIcon src={iconHorario} slot="start" />
            <div className="contentList">
              <h1>Horários de Funcionamento</h1>
              {loadHorarios()}
            </div>
          </IonItem>
          <IonItem lines="full" hidden={true}>
            <IonIcon src={iconTempo} slot="start" />
            <div className="contentList">
              <h1>Tempos Médios de Carga/Descarga</h1>
              <div className="horario">
                <IonLabel>Histórico</IonLabel>
                <IonLabel>{estabelecimento.medias.historico}</IonLabel>
              </div>
              <div className="horario">
                <IonLabel>Hoje</IonLabel>
                <IonLabel>{estabelecimento.medias.hoje}</IonLabel>
              </div>
            </div>
          </IonItem>


          <IonListHeader lines="full" style={{ "display": "block" }}>
            <IonLabel>Notas Fiscais</IonLabel>
            <IonItem hidden={acessos?.operacoes != 'edicao'} lines="none" >
              <IonIcon src={addCircle} color="primary" onClick={() => { setShowModalCodigoBarrasDigitar(true);/*openScanner();*/ }}></IonIcon>
            </IonItem>
          </IonListHeader>
          <IonItem lines="none" hidden={dadosOperacao && dadosOperacao.operacaoChaveAcesso ? (dadosOperacao.operacaoChaveAcesso === "0" ? true : false) : false}>
            <IonIcon src={receiptOutline} slot="start" />
            <div className="contentList">
              <h1>Nota Fiscal</h1>
              <h2>{dadosOperacao && dadosOperacao.operacaoChaveAcesso ? dadosOperacao.operacaoChaveAcesso.substr(25, 9) : ""} </h2>
            </div>
            <IonIcon slot="end" color="primary" src={eye} onClick={() => { window.open("https://www.nfe.fazenda.gov.br/portal/consultaRecaptcha.aspx?tipoConsulta=completa&nfe=" + (dadosOperacao && dadosOperacao.operacaoChaveAcesso ? dadosOperacao.operacaoChaveAcesso : ""), '_blank') /* tipoConteudo=7PhJ+gAVw2g=& */ }} ></IonIcon>
          </IonItem>
          {notasAdicionais.map((notasValue: any, index: any) => (
            <IonItem lines="none">
              <IonIcon src={receiptOutline} slot="start" />
              <div className="contentList">
                <h1>Nota Fiscal</h1>
                <h2>{notasValue ? notasValue.notaFiscal.substr(25, 9) : ""} </h2>
              </div>
              <IonNote hidden={true} slot="end">{notasValue ? notasValue.tipoOperacao : ""}</IonNote>
              <IonIcon slot="end" color="primary" src={eye} onClick={() => { window.open("http://www.nfe.fazenda.gov.br/portal/consultaRecaptcha.aspx?tipoConsulta=completa&nfe=" + (notasValue ? notasValue.notaFiscal : ""), '_blank')  /*window.open("http://www.nfe.fazenda.gov.br/portal/consultaRecaptcha.aspx?tipoConteudo=XbSeqxE8pl8=&tipoConsulta=completa&nfe=" + (notasValue ? notasValue.notaFiscal : ""), '_blank')*/ }} ></IonIcon>
            </IonItem>
          ))}


          <IonListHeader lines="full">
            <IonLabel>Fotos</IonLabel>
            <IonItem hidden={acessos?.operacoes != 'edicao'} lines="none" >
              <IonIcon src={addCircle} color="primary" onClick={async () => { await takePhoto(params.operacaoid).catch((e) => { console.log("cancelado"); }); }}></IonIcon>
            </IonItem>
          </IonListHeader>
          <IonItem hidden={false} lines="none">

            <IonGrid>
              <IonRow>
                {photos.map((photo, index) => (
                  <IonCol size="6" key={index}>
                    <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '0px', paddingBottom: '10px', position: 'relative' }}>
                      <div style={{ maxWidth: '350px', width: '100%', minHeight: '100px' }}>
                        <a target='_blank' href={photo.webviewpath?.replace("small", "big")}>
                          <img style={{ width: '100%', borderRadius: '5px' }} src={photo.webviewpath?.replace("small", "big")}></img>
                        </a>

                        <IonButton fill='clear' color="tertiary" size="small" expand="block" onClick={() => deletarFoto(photo.webviewpath)}>Excluir</IonButton>

                      </div>
                    </div>
                  </IonCol>
                ))}
              </IonRow>
            </IonGrid>

          </IonItem>


          <IonListHeader lines="full">
            <IonLabel>Anotações</IonLabel>
            <IonItem hidden={acessos?.operacoes != 'edicao'} lines="none" >
              <IonIcon src={addCircle} color="primary" onClick={async () => {


                mostartAlerta({
                  backdropDismiss: false,
                  header: 'Registrar anotação na operação',
                  message: 'Digite a anotação abaixo',
                  inputs: [
                    {
                      name: 'mensagem',
                      type: 'textarea',
                      id: 'name2-id',
                      placeholder: 'Digite aqui a anotação',
                    }],
                  buttons: ['Cancel', {
                    text: 'Incluir', handler: (d) => {
                      setShowLoadingPage(true);
                      let retorno = registrarAnotacao(dadosOperacao.pk.replace(/#/g, '%23'), d.mensagem);
                      if (retorno) {
                        retorno?.then(async e => {
                          let body = await e.json();
                          if (e.status === 200) {
                            if (dadosOperacao.anotacoes) {
                              dadosOperacao.anotacoes?.push({ data: body.data, anotacao: d.mensagem, pessoa: body.pessoa });
                            }
                            else {
                              dadosOperacao.anotacoes = [{ data: body.data, anotacao: d.mensagem, pessoa: body.pessoa }];
                            }

                            mostrarToast({
                              duration: 2000,
                              position: 'middle',
                              buttons: [{ text: 'ok', handler: () => fecharToast() }],
                              message: 'Sucesso na inclusão!'
                            });
                          }
                          else {
                            mostrarToast({
                              position: 'middle',
                              buttons: [{ text: 'ok', handler: () => fecharToast() }],
                              message: 'Erro na inclusão!'
                            });
                          }
                        }).catch((e) => {
                          console.log(e);
                          mostrarToast({
                            position: 'middle',
                            buttons: [{ text: 'ok', handler: () => fecharToast() }],
                            message: 'Erro na inclusão!'
                          });
                        }).finally(() => {
                          setShowLoadingPage(false);
                        });
                        return true;
                      }
                      else {
                        mostrarToast({
                          duration: 2000,
                          position: 'middle',
                          buttons: [{ text: 'ok', handler: () => fecharToast() }],
                          message: 'Digite uma anotação para incluir.'
                        });
                        setShowLoadingPage(false);
                        return false;
                      }
                    }
                  }],
                  onDidDismiss: (e) => { console.log('did dismiss'); }
                })



              }}></IonIcon>
            </IonItem>
          </IonListHeader>

          {loadAnotacoes()}

        </IonList>
        {/* <IonActionSheet
          backdropDismiss={true}
          translucent={true}
          isOpen={showActionSheet}
          onDidDismiss={() => setShowActionSheet(false)}
          cssClass='my-custom-class'
          buttons={getButtons()}
        /> */}
        <IonAlert isOpen={alertConfirmar}
          onDidDismiss={() => setAlertConfirmar(false)}
          header={'Encerrar operação'}
          message={'Deseja <strong>encerrar</strong>?'}
          buttons={[
            {
              text: 'Cancelar',
              role: 'cancel',
            },
            {
              text: 'Confirmar',
              cssClass: 'primary',
              handler: async () => {
                setShowLoadingPage(true);
                await encerrar2().then(res => {
                  console.log(res);
                  setRecarregarOperacao(true);
                  history.goBack()
                }).catch(() => console.log("Erro na chamada"))
                  .finally(() => {
                    setShowLoadingPage(false);
                  });
              }
            }
          ]}
        />

        <IonModal isOpen={abrirFormulario} onDidDismiss={() => { setAbrirFormulario(false) }} className="my-custom-class2">
          <Formulario operacaoid={params.operacaoid} closeAction={closeModal} encerrarAction={encerrarAction} ></Formulario>
        </IonModal>

        <IonModal isOpen={showCarregando} className='my-custom-class aguarde'>
          <IonLabel className="helper">Aguarde, realizando operação</IonLabel>
        </IonModal>
        <IonModal isOpen={showBrowserCamera} onDidDismiss={() => { setShowBrowserCamera(false); cancelarScan(); }} className='my-custom-class camera' onDidPresent={() => { startCamera() }}>
          <video hidden={false} style={{ display: "flex", flexDirection: "column" }} id="video" autoPlay muted playsInline></video>
          <IonLabel className="helper">Aponte a camera para o <b>código de barras</b> que será feita a leitura.</IonLabel>

          <div className="actions" style={{ width: "100%", display: "flex", flexDirection: "column" }}>
            <IonButton fill="solid" slot="end" color="tertiary" onClick={() => { setFront(!front); startCamera(); }}>Virar camera</IonButton>
            <IonButton fill="clear" slot="end" color="tertiary" onClick={() => { cancelarScan(); setShowBrowserCamera(false); }}>Cancelar</IonButton>
          </div>
        </IonModal>
        <IonModal isOpen={showModalCodigoBarrasDigitar} onDidPresent={() => { inputRef.current.setFocus(); }} onDidDismiss={() => { setShowModalCodigoBarrasDigitar(false); setCodigoBarras(''); setAlertaPreenchimento(false); }} >
          <IonHeader>
            <IonToolbar color="primary">
              <IonTitle>Incluir Nota Fiscal</IonTitle>
              <IonButtons slot="start" onClick={() => { cancelarScan(); setShowModalCodigoBarrasDigitar(false); }}>
                <IonIcon slot="icon-only" icon={arrowBack}></IonIcon>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent class="ion-padding">
            <IonItem lines="none">
              <IonIcon hidden={!alertaPreenchimento} size="large" slot="start" src={alertCircle} color="danger" />
              <IonLabel hidden={!alertaPreenchimento} color="danger" style={{ "font-size": "14px" }} >Campo obrigatório</IonLabel>
            </IonItem>
            <IonItem lines="none">
              <IonLabel className="ion-text-wrap" position="stacked">Informe a <b>chave de acesso</b> (código de barras) na <b>nota</b>:</IonLabel>
              <IonTextarea ref={(ref) => inputRef.current = ref} rows={3} inputMode="numeric" maxlength={54} value={codigoBarras} placeholder="Utilize o leitor de código de barras ou digite" onIonChange={e => space(e.detail.value!, 4)}></IonTextarea>
            </IonItem>
          </IonContent>
          <IonFooter className="ion-no-border" >
            <IonToolbar>
              <IonButton expand="block" size='large' disabled={!botaoAtivo} onClick={() => { registrarNotaFiscal((codigoBarras || "").replace(/ /g, "")) }}>Registrar Nota!</IonButton>
              <IonButton expand="block" fill="clear" color="tertiary" onClick={() => { openScanner() }}>Utilizar câmera do celular</IonButton>
            </IonToolbar>
          </IonFooter>
        </IonModal>

        {/* <IonActionSheet
          backdropDismiss={true}
          translucent={true}
          isOpen={showActionSheetFormulario}
          onDidDismiss={() => setShowActionSheetFormulario(false)}
          cssClass='my-custom-class'
          buttons={[{
            text: "Relatório",
            icon: clipboardOutline,
            handler: () => {
              setAbrirFormulario(true);
            }
          }, {
            text: 'Cancelar',
            icon: closeOutline,
            role: 'cancel',
            handler: () => {
              console.log('Cancel clicked');
            }
          }]}
        >
        </IonActionSheet> */}

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoadingPage}
          onDidDismiss={() => { setShowLoadingPage(false); }}
          spinner='circles'
        />
      </IonContent>
      {/* <IonFooter className="ion-no-border" hidden={(temHoraFim)}>
        <IonToolbar>
          <IonButton size='large' expand="block" onClick={() => setShowActionSheet(true)}>Ações</IonButton>
        </IonToolbar>
      </IonFooter> */}

      {/* <IonFooter className="ion-no-border" hidden={(!temHoraFim)}>
        <IonToolbar>
          <IonButton size='large' expand="block" onClick={() => setShowActionSheetFormulario(true)}>Ações</IonButton>
        </IonToolbar>
      </IonFooter> */}
    </IonPage>
  );


};

export default Acompanhar;