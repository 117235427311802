import { IonBadge, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonLabel, IonLoading, IonMenuButton, IonModal, IonPage, IonRow, IonTextarea, IonTitle, IonToast, IonToolbar, useIonViewDidEnter } from '@ionic/react';
import { appsOutline, arrowBack, calendarOutline, chatbubbles, chatbubblesOutline, clipboardOutline, copy, desktopOutline, flagOutline, notifications, trendingUpOutline } from 'ionicons/icons';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Autoplay, Navigation, Pagination, Swiper as SwiperInterface } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import '@ionic/react/css/ionic-swiper.css';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { MessageContext } from '../../../../../contexts/MessageContext';
import { pagamentoMensalidade } from '../../../../../Utils/Services';
import Menu from '../../Components/Menu';
import Notificacoes from '../Notificacoes/Notificacoes';
import './Inicio.css';


type Props = {
  abrirMenu: boolean;
};

const Inicio: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  const [showLoadingPage, setShowLoadingPage] = useState(false);

  const { loja, setLoja } = useContext(MessageContext);

  const [acessos] = useState<any>(JSON.parse(window.localStorage.getItem("acessos") || '{}'));
  const { esconderMenu, setEsconderMenu } = useContext(MessageContext);
  const { nomeMenu, setNomeMenu } = useContext(MessageContext);

  const { notificacoes, setNotificacoes } = useContext(MessageContext);
  const [showNotificacoes, setShowNotificacoes] = useState(false);

  const { mensagens, setMensagens } = useContext(MessageContext);

  const [swiperInstance, setSwiperInstance] = useState<SwiperInterface>();

  const [showModalQrCode, setShowModalQrCode] = useState(false);
  const [qrCodeData, setQRCodeData] = useState<any>();
  const [qrCode, setQRCode] = useState<any>();
  const { idPagamento, setIdPagamento } = useContext(MessageContext);
  const { isPaymentCompleted, setIsPaymentCompleted } = useContext(MessageContext);
  const [valorPagamento, setValorPagamento] = useState<any>();

  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");


  const { dadosEmpresa, setDadosEmpresa } = useContext(MessageContext);

  const [timeLeft, setTimeLeft] = useState(6 * 60); // 10 minutos em segundos


  useIonViewDidEnter(() => {
    setEsconderMenu(false);
    setNomeMenu("");
  });


  async function closeModalNotificacoes() {
    setShowNotificacoes(false);
  }

  async function closeModalSimpleNotificacoes() {
    setShowNotificacoes(false);
  }

  useEffect(() => {

    if (isPaymentCompleted && showModalQrCode) {
      setShowModalQrCode(false);
    }

  }, [isPaymentCompleted]);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (showModalQrCode) {
      // Reseta o contador para 10 minutos sempre que o modal é aberto
      setTimeLeft(6 * 60); // 10 minutos em segundos (10 * 60)

      // Inicia o intervalo de 1 segundo para o countdown
      timer = setInterval(() => {
        setTimeLeft(prevTime => {
          if (prevTime > 0) {
            return prevTime - 1;
          } else {
            clearInterval(timer); // Limpa o intervalo quando o tempo chega a 0
            // Quando o tempo acaba, redireciona o usuário
            handleQrCodeExpired();
            return 0;
          }
        });
      }, 1000);
    }

    // Limpa o timer quando o modal fecha ou o componente é desmontado
    return () => clearInterval(timer);

  }, [showModalQrCode]);

  // Função que será chamada quando o QR Code expira
  const handleQrCodeExpired = () => {
    setTextToast("Código expirado. Gere um novo para efetuar o pagamento.");
    setShowToast(true);

    setShowModalQrCode(false);
  };

  // Converte o tempo restante em minutos e segundos
  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;

  return (
    <>
      <Menu id="main-content-inicio"></Menu>
      <IonPage id="main-content-inicio" className="Inicio">
        <IonHeader className="ion-no-border">
          <IonToolbar color="primary">
            <IonButtons slot="start">


              <IonMenuButton></IonMenuButton>
            </IonButtons>


            <IonButtons slot="end">
              <IonButton onClick={() => { setShowNotificacoes(true); }}>
                <IonIcon slot="icon-only" icon={notifications} />
                <IonBadge id="cart-badge" color="danger">{notificacoes?.length}</IonBadge>
              </IonButton>
            </IonButtons>

            <IonButtons slot="end" hidden={acessos?.chat != 'leitura' && acessos?.chat != 'edicao'}>
              <IonButton onClick={() => { history.push("./chat"); }}>
                <IonIcon slot="icon-only" icon={chatbubbles} />
                <IonBadge id="cart-badge" color="danger">{mensagens?.length}</IonBadge>
              </IonButton>
            </IonButtons>

          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen={false}>

          {dadosEmpresa && Object.keys(dadosEmpresa).length > 0 && (
            <IonToolbar color="warning" hidden={dadosEmpresa.tempoRestanteParaPagamento > 20 || dadosEmpresa.pk != dadosEmpresa.gsi3pk}>
              <IonGrid>
                <IonRow class="ion-align-items-center">

                  <IonCol size="auto">
                    <IonLabel>
                      {(dadosEmpresa.bloquearAcesso) ?
                        (
                          <strong>Seu acesso foi bloqueado. Realize o pagamento para continuar utilizando nossos serviços.</strong>
                        )
                        : (
                          <strong>
                            {(dadosEmpresa.tempoRestanteParaPagamento || 0) === 0
                              ? "Amanhã seu acesso será bloqueado. Realize o pagamento para manter o acesso."
                              : (dadosEmpresa.tempoRestanteParaPagamento === 1
                                ? `Falta 1 dia para o vencimento da sua licença. Realize o pagamento para manter o acesso.`
                                : `Faltam ${dadosEmpresa.tempoRestanteParaPagamento || 0} dias para o vencimento da sua licença. ${dadosEmpresa.tempoRestanteParaPagamento <= 10
                                  ? "Realize o pagamento para manter o acesso."
                                  : "Garanta o próximo mês."
                                }`)
                            }

                          </strong>
                        )
                      }
                    </IonLabel>

                  </IonCol>
                  <IonCol class="ion-text-right">


                    <IonButton color="danger" size="small" onClick={async () => {
                      var token: string = "";
                      var tkn = localStorage.getItem("token");
                      if (typeof (tkn) === "string") { token = tkn; }

                      let resp = await fetch(pagamentoMensalidade.url.replace("{id}", loja.replaceAll(/#/g, "%23")),
                        {
                          method: pagamentoMensalidade.method,
                          headers: [
                            ["token", token]
                          ],
                          credentials: 'include'
                        });
                      let retorno = await resp.json();

                      if (resp.status === 400 || resp.status === 401) {
                        setTextToast("Ocorreu um erro ao gerar o Pagamento, entre em contato com o suporte.");
                        setShowToast(true);
                      }
                      else {
                        if (retorno.message) {
                          setIdPagamento(retorno.message.id);
                          setQRCode(retorno.message.point_of_interaction.transaction_data.qr_code);
                          setQRCodeData(retorno.message.point_of_interaction.transaction_data.qr_code_base64);
                          setValorPagamento(retorno.message.transaction_amount);
                          setShowModalQrCode(true);
                        }
                        else {
                          setTextToast("Ocorreu um erro ao gerar o Pagamento, entre em contato com o suporte.");
                          setShowToast(true);
                        }
                      }
                    }}>
                      Realizar Pagamento
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>

            </IonToolbar>
          )}

          <div style={{ padding: '5px' }}>

            <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '0px', paddingBottom: '0px' }}>
              <div id='containerInicioCategorias' style={{ maxWidth: '1024px' }}>

                <IonGrid fixed={true} style={{ paddingTop: '12px', maxWidth: "690px" }}>

                  <IonRow>
                    <IonCol size={"6"} size-md={"6"} size-lg={"6"} hidden={(acessos?.vendas != 'leitura' && acessos?.vendas != 'edicao')}>
                      <IonCard onClick={() => { history.push("./vendas") }} style={{ width: '100%', maxWidth: '100%', height: "134px" }} disabled={dadosEmpresa.bloquearAcesso} >
                        <IonCardHeader style={{ paddingTop: '14px', paddingBottom: '0px' }}>
                          <IonIcon color='primary' style={{ border: '1px solid #ccc', borderRadius: '10px', padding: '10px' }} icon={trendingUpOutline} />
                          <IonCardTitle style={{ fontSize: "16px", fontWeight: 'bold' }}>Vendas</IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent style={{ fontSize: "12px" }}>
                          Funil de vendas e orçamentos
                        </IonCardContent>
                      </IonCard>
                    </IonCol>

                    <IonCol size={"6"} size-md={"6"} size-lg={"6"} hidden={(acessos?.balcao != 'leitura' && acessos?.balcao != 'edicao')}>
                      <IonCard onClick={() => { history.push("./caixa") }} style={{ width: '100%', maxWidth: '100%', height: "134px" }} disabled={dadosEmpresa.bloquearAcesso}>
                        <IonCardHeader style={{ paddingTop: '14px', paddingBottom: '0px' }}>
                          <IonIcon color='primary' style={{ border: '1px solid #ccc', borderRadius: '10px', padding: '10px' }} icon={desktopOutline} />
                          <IonCardTitle style={{ fontSize: "16px", fontWeight: 'bold' }}>{"Balcão"} </IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent style={{ fontSize: "12px" }}>
                          PDV
                        </IonCardContent>
                      </IonCard>
                    </IonCol>

                    <IonCol size={"6"} size-md={"6"} size-lg={"6"} hidden={(acessos?.comandas != 'leitura' && acessos?.comandas != 'edicao')}>
                      <IonCard onClick={() => { history.push("./comandas") }} style={{ width: '100%', maxWidth: '100%', height: "134px" }} disabled={dadosEmpresa.bloquearAcesso}>
                        <IonCardHeader style={{ paddingTop: '14px', paddingBottom: '0px' }}>
                          <IonIcon color='primary' style={{ border: '1px solid #ccc', borderRadius: '10px', padding: '10px' }} icon={clipboardOutline} />
                          <IonCardTitle style={{ fontSize: "16px", fontWeight: 'bold' }}>{window.localStorage.getItem("nomeInternoMesasOuComandas") || "Mesas"}</IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent style={{ fontSize: "12px" }}>
                          Efetue {window.localStorage.getItem("servicoTipo") || "Projeto"} diretamente nas {window.localStorage.getItem("nomeInternoMesasOuComandas") || "Mesas"}
                        </IonCardContent>
                      </IonCard>
                    </IonCol>


                    <IonCol size={"6"} size-md={"6"} size-lg={"6"} hidden={(acessos?.projetos != 'leitura' && acessos?.projetos != 'edicao')}>
                      <IonCard onClick={() => { history.push("./projetos") }} style={{ width: '100%', maxWidth: '100%', height: "134px" }} disabled={dadosEmpresa.bloquearAcesso}>
                        <IonCardHeader style={{ paddingTop: '14px', paddingBottom: '0px' }}>
                          <IonIcon color='primary' style={{ border: '1px solid #ccc', borderRadius: '10px', padding: '10px' }} icon={appsOutline} />
                          <IonCardTitle style={{ fontSize: "16px", fontWeight: 'bold' }}>{window.localStorage.getItem("servicoTipoPlural") || "Projetos"}</IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent style={{ fontSize: "12px" }}>
                          Crie e acompanhe {window.localStorage.getItem("servicoTipoPlural") || "Projetos"}
                        </IonCardContent>
                      </IonCard>
                    </IonCol>



                    <IonCol size={"6"} size-md={"6"} size-lg={"6"} hidden={(acessos?.agenda != 'leitura' && acessos?.agenda != 'edicao')}>
                      <IonCard onClick={() => { history.push("./agendamentoseventoscommenu") }} style={{ width: '100%', maxWidth: '100%', height: "134px" }} disabled={dadosEmpresa.bloquearAcesso}>
                        <IonCardHeader style={{ paddingTop: '14px', paddingBottom: '0px' }}>
                          <IonIcon color='primary' style={{ border: '1px solid #ccc', borderRadius: '10px', padding: '10px' }} icon={calendarOutline} />
                          <IonCardTitle style={{ fontSize: "16px", fontWeight: 'bold' }}>Agenda</IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent style={{ fontSize: "12px" }}>
                          Agende entregas, serviços e reuniões
                        </IonCardContent>
                      </IonCard>
                    </IonCol>
                    <IonCol size={"6"} size-md={"6"} size-lg={"6"} hidden={(acessos?.operacoes != 'leitura' && acessos?.operacoes != 'edicao')}>
                      <IonCard onClick={() => { history.push("./operacoes") }} style={{ width: '100%', maxWidth: '100%', height: "134px" }} disabled={dadosEmpresa.bloquearAcesso}>
                        <IonCardHeader style={{ paddingTop: '14px', paddingBottom: '0px' }}>
                          <IonIcon color='primary' style={{ border: '1px solid #ccc', borderRadius: '10px', padding: '10px' }} icon={flagOutline} />
                          <IonCardTitle style={{ fontSize: "16px", fontWeight: 'bold' }}>Operações</IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent style={{ fontSize: "12px" }}>
                          Acompanhe o dia a dia da equipe
                        </IonCardContent>
                      </IonCard>
                    </IonCol>


                    {/* <IonCol size={"6"} size-md={"6"} size-lg={"6"} hidden={(acessos?.financeiro != 'leitura' && acessos?.financeiro != 'edicao')}>
                      <IonCard onClick={() => { history.push("./financeiro") }} style={{ width: '100%', maxWidth: '100%', height: "134px" }} disabled={dadosEmpresa.bloquearAcesso}>
                        <IonCardHeader style={{ paddingTop: '14px', paddingBottom: '0px' }}>
                          <IonIcon color='primary' style={{ border: '1px solid #ccc', borderRadius: '10px', padding: '10px' }} icon={logoUsd} />
                          <IonCardTitle style={{ fontSize: "16px", fontWeight: 'bold' }}>Financeiro</IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent style={{ fontSize: "12px" }}>
                          Receitas e despesas em um único lugar
                        </IonCardContent>
                      </IonCard>
                    </IonCol> */}
                    <IonCol size={"6"} size-md={"6"} size-lg={"6"}>
                      <a target='_blank' href={"https://api.whatsapp.com/send?phone=5511941744046"} style={{ textDecoration: 'none' }}>
                        <IonCard
                          // onClick={() => { history.push("./menuconfiguracoes") }}
                          style={{ width: '100%', maxWidth: '100%', height: "134px" }}>
                          <IonCardHeader style={{ paddingTop: '14px', paddingBottom: '0px' }}>

                            <IonIcon color="primary" style={{ border: '1px solid #ccc', borderRadius: '10px', padding: '10px' }} icon={chatbubblesOutline} />

                            <IonCardTitle style={{ fontSize: "16px", fontWeight: 'bold' }}>Ajuda</IonCardTitle>
                          </IonCardHeader>
                          <IonCardContent style={{ fontSize: "12px" }}>
                            Como podemos ajudar você?
                          </IonCardContent>
                        </IonCard>
                      </a>
                    </IonCol>


                  </IonRow>
                </IonGrid>
              </div>
            </div>
            <IonGrid fixed={true} style={{ paddingTop: '0px', paddingBottom: '10px', paddingLeft: '18px', paddingRight: '18px' }}>
              <IonRow>
                <IonCol size={"12"} size-md={"12"} size-lg={"12"} style={{ padding: '0px' }}>
                  <Swiper
                    spaceBetween={30}
                    centeredSlides={true}
                    autoplay={{
                      delay: 5000,
                      disableOnInteraction: false,
                    }}
                    pagination={{
                      clickable: true
                    }}
                    navigation={false}
                    modules={[Autoplay, Pagination, Navigation]}

                    onSwiper={(swiper) => {
                      setSwiperInstance(swiper);
                    }} >
                    <div>
                      <SwiperSlide>
                        <img src="https://xeguei-plt-welcome.s3.sa-east-1.amazonaws.com/banners/banner1.png" alt="Banner 1" />
                      </SwiperSlide>
                      <SwiperSlide>
                        <img src="https://xeguei-plt-welcome.s3.sa-east-1.amazonaws.com/banners/banner2.png" alt="Banner 2" />
                      </SwiperSlide>
                      {/* Adicione mais slides conforme necessário */}
                    </div>
                  </Swiper>
                </IonCol>
              </IonRow>

            </IonGrid>
          </div>


          <div className="content">

            <IonModal className="fullscreen-modal" isOpen={showModalQrCode} onDidDismiss={() => {
              setShowModalQrCode(false);
            }}>
              <IonHeader>
                <IonToolbar color="primary">
                  <IonButtons slot="start">
                    <IonIcon slot="icon-only" icon={arrowBack} onClick={() => setShowModalQrCode(false)} />
                  </IonButtons>
                  <IonTitle>Detalhes do pagamento</IonTitle>
                </IonToolbar>
              </IonHeader>
              <IonContent>




                <IonCard className="div-qrcode-pagamento">
                  <IonCardContent>

                    {/* Título Assinatura */}
                    <IonLabel className="qr-code-pagamento">
                      <h2><b>Assinatura XEGUEI</b></h2>
                    </IonLabel>

                    {/* Valor do pagamento */}
                    <IonLabel>
                      <p className="payment-title">Pague <b>R$ {valorPagamento?.toLocaleString('pt-BR', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}</b> via <b>Pix</b></p>
                    </IonLabel>

                    {/* Informações de vencimento */}
                    {/* <IonLabel>
                      <p className="payment-info">Vencimento: 30 minutos</p>
                    </IonLabel> */}

                    {/* Texto explicativo */}
                    <IonLabel className="qr-code-pagamento-margin">
                      <h3>Para pagar, escolha uma destas opções:</h3>
                    </IonLabel>

                    {/* Código QR */}
                    <IonCardContent className="qr-code-pagamento">
                      <IonLabel>
                        <p>Código QR</p>
                      </IonLabel>
                      {qrCodeData && <img src={`data:image/png;base64,${qrCodeData}`} alt="QR Code" />}
                    </IonCardContent>

                    {/* Código de pagamento e botão de cópia */}
                    <IonCardContent className="qr-code-pagamento">
                      <IonLabel>
                        <p>Código de pagamento</p>
                      </IonLabel>
                      <div className="copy-code-container">
                        {/* Substituído por IonTextarea */}
                        <IonTextarea
                          readonly={true}
                          style={{ "--background": "#eeeeee" }}
                          rows={2}
                          value={qrCode}
                        />
                        <IonButton fill="clear" onClick={() => {
                          navigator.clipboard.writeText(qrCode);
                          setTextToast("Código copiado");
                          setShowToast(true);
                        }}>
                          <IonIcon color="primary" icon={copy} />
                        </IonButton>
                      </div>

                    </IonCardContent>


                    <IonLabel className="qr-code-pagamento">
                      Código válido por: <h1>{minutes}:{seconds < 10 ? `0${seconds}` : seconds}</h1>
                    </IonLabel>
                    {/* Instruções de como pagar */}
                    <IonCardContent>

                      <IonLabel>
                        <p><b>Como pagar?</b></p>
                        <ol>
                          <li>Entre no app ou site do seu banco e escolha a opção de pagamento via Pix.</li>
                          <li>Escaneie o código QR ou copie e cole o código de pagamento.</li>
                          <li>Pronto! Agora é só aguardar o pagamento ser processado.</li>
                        </ol>
                      </IonLabel>
                    </IonCardContent>


                  </IonCardContent>
                </IonCard>








              </IonContent>

            </IonModal>

            <IonModal isOpen={showNotificacoes} onDidDismiss={() => { setShowNotificacoes(false); }}>
              <Notificacoes closeAction={closeModalNotificacoes} closeActionSimple={closeModalSimpleNotificacoes}></Notificacoes>
            </IonModal>

          </div >


          <IonToast
            isOpen={showToast}
            position="middle" //top, bottom, middle
            onDidDismiss={() => setShowToast(false)}
            message={textToast}
            duration={3000}
          />

          <IonLoading
            cssClass='my-custom-class'
            isOpen={showLoadingPage}
            onDidDismiss={() => { setShowLoadingPage(false); }}
            spinner='circles'
          />
        </IonContent >
      </IonPage >
    </>
  );
};

export default Inicio;