import { IonBadge, IonButton, IonButtons, IonCard, IonCardContent, IonCol, IonContent, IonFab, IonFabButton, IonFooter, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonLoading, IonModal, IonPage, IonReorder, IonReorderGroup, IonRow, IonTitle, IonToast, IonToolbar, ItemReorderEventDetail, useIonAlert, useIonViewDidEnter } from '@ionic/react';
import imageCompression from 'browser-image-compression';
import { add, arrowBack, fastFoodOutline, pencil, pizzaOutline, reorderTwo, restaurantOutline, trash, trashOutline } from 'ionicons/icons';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { MessageContext } from '../../../../../contexts/MessageContext';
import { assinaUrl, empresaAlterar, ordernarItemCategoriaProdutosEmpresa, removerProdutosCategoriaNovoEmpresa, requestService } from '../../../../../Utils/Services';
import imgDefaultImage from './imgs/defaultImage.svg';
import "./ProdutosCategoriasNovo.css";


type Props = {
  abrirMenu: boolean;
  projetoid: string;
  empresaid: string;
  configs: Object;
  abrirInserirAgendamento: boolean;
  onDismiss: () => void;
};

const ProdutosCategoriasNovo: React.FC<Props> = (props: Props) => {

  const history = useHistory<any>();
  const [showLoading, setShowLoading] = useState(false);
  const [listaCategoriaProdutos, setListaCategoriaProdutos] = useState<any>([]);

  const [showDetalhes, setShowDetalhes] = useState(false);
  const contentRef = useRef<HTMLIonContentElement | null>(null);
  const [index, setIndex] = useState("");
  const [nome, setNome] = useState("");

  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");


  const { loja, setLoja } = useContext(MessageContext);
  const [acessos] = useState<any>(JSON.parse(window.localStorage.getItem("acessos") || '{}'));
  const { esconderMenu, setEsconderMenu } = useContext(MessageContext);
  const { nomeMenu, setNomeMenu } = useContext(MessageContext);

  const [sizes, setSizes] = useState([{ name: '', sabores: 0 }]);

  const [massas, setMassas] = useState([{ name: '', price: 0 }]);
  const [bordas, setBordas] = useState([{ name: '', price: 0 }]);

  const [tipo, setTipo] = useState('');
  const [etapa, setEtapa] = useState(1);

  const [isDisabled, setIsDisabled] = useState(true);

  const inputRef = useRef<any>(null);


  const [fileDataURL, setFileDataURL] = useState<any>(null);
  const [fileArquivos, setFileArquivos] = useState<any>();
  const imageMimeType = /image\/(png|jpg|jpeg|avif|webp)/i;

  const [mostartAlerta] = useIonAlert();

  const { dadosEmpresa, setDadosEmpresa } = useContext(MessageContext);


  useEffect(() => {

    // console.log("*****************", fileArquivos);
    let fileReader: FileReader, isCancel = false;
    if (fileArquivos) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        const result = e.target;
        if (result && !isCancel) {
          setFileDataURL(result.result);
          // atualizarTamanhoImagem();
        }
      }
      fileReader.readAsDataURL(fileArquivos);
    }
    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    }
  }, [fileArquivos]);

  useIonViewDidEnter(() => {
    setEsconderMenu(true);
    setNomeMenu("");

    setListaCategoriaProdutos(dadosEmpresa.categoriaProdutos || []);

    //carregarConta();
    // if (inputRef.current) {
    //   inputRef.current.setFocus();
    // }
  });


  // function carregarConta() {

  //   const consultar = async () => {

  //     console.log("aasasasasa", loja);
  //     let id = loja;

  //     requestService(empresaCarregar.url.replace("{id}", id.replaceAll(/#/g, '%23')).concat("?filtro=", Buffer.from(loja).toString("base64")), {
  //       method: empresaCarregar.method,
  //       headers: [
  //         ["token", window.localStorage.getItem("token")]
  //       ],
  //       credentials: 'include'
  //     },
  //       history,
  //       (response: any) => {
  //         console.log(response);
  //         setListaCategoriaProdutos(response.Item.categoriaProdutos || []);
  //         setShowLoading(false);
  //       },
  //       (error: any) => {
  //         console.log(error);
  //         history.replace("/login/");
  //       });

  //   }

  //   consultar();
  // }



  function deletarCategoria(indice: any) {

    var token: string = "";
    var tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") { token = tkn; }

    const delCategoria = async () => {
      const resp = await fetch(removerProdutosCategoriaNovoEmpresa.url.replace("{id}", loja.replace(/#/g, "%23")),
        {
          method: removerProdutosCategoriaNovoEmpresa.method,
          headers: [
            ["token", token]
          ],
          body: JSON.stringify({ 'index': indice }),
          credentials: 'include'
        });
      const response = await resp.json();

      if (response) {
        setListaCategoriaProdutos(response.message.Attributes.categoriaProdutos || []);
        dadosEmpresa.categoriaProdutos = response.message.Attributes.categoriaProdutos;
        setDadosEmpresa(dadosEmpresa);


        console.log("retorno", response);
        setTextToast("Categoria removida com sucesso.");
        setShowToast(true);
      }
      // carregarConta();
    }

    delCategoria();

  }


  async function assinaUploadS3ArquivoESalvar(file: any) {
    const atualizar = async () => {
      let fileParts = file && file.name ? file.name.split('.') : [];
      let fileNameArquivo = fileParts[0] || '';
      let fileTypeArquivo = fileParts[1] || '';

      let params = {
        nome: fileNameArquivo,
        tipo: fileTypeArquivo
      }

      try {
        setShowLoading(true);

        let urlImagem = '', nomeImagem = '', tipoImagem = '';

        // console.log("file", file);
        if (file && file.name) {
          // Assina URL
          const resp = await fetch(assinaUrl.url.concat("?pasta=produtos-categoria&bucket=xeguei-mobile-publico"), {
            method: assinaUrl.method,
            headers: [
              ["token", localStorage.getItem("token") || ""]
            ],
            body: JSON.stringify(params),
            credentials: 'include'
          });

          const response = await resp.json();

          if (response.Item) {




            // const compressedFile = await imageCompression(file, {
            //   maxSizeMB: 0.1,
            //   maxWidthOrHeight: 600,
            //   useWebWorker: true,
            //   initialQuality: 0.5
            // });

            // console.log("Tamanho comprimido:", compressedFile.size);

            // console.log("fileTypeArquivo", fileTypeArquivo);

            const uploadToS3 = await fetch(response.Item.urlAssinado, {
              method: "PUT",
              headers: {
                "Content-Type": "image/" + fileTypeArquivo,
              },
              body: file,
            });

            if (uploadToS3.status === 200) {
              urlImagem = response.Item.urlPublica;
              nomeImagem = response.Item.fileName;
              tipoImagem = fileTypeArquivo;
            } else {
              throw new Error('Erro ao fazer upload do arquivo.');
            }
          } else {
            throw new Error('Erro ao assinar a URL.');
          }
        }

        const node = { sizes };
        console.log('Salvando nó:', node);


        let obj = {
          nome: nome,
          tamanhos: sizes,
          massas: massas,
          bordas: bordas,
          tipo: tipo,
          urlImagem: urlImagem,
          nomeImagem: nomeImagem,
          tipoImagem: tipoImagem,
        };

        const objectRequest = {
          index: index,
          nome: obj
        }
        console.log("objectRequest", objectRequest);

        let id = loja;

        console.log("id", id);

        await requestService(
          empresaAlterar.url.replace("{id}", id.replaceAll("#", "%23")).concat("?produtoscategorianovo=sim"),
          {
            method: empresaAlterar.method,
            body: JSON.stringify(objectRequest),
            headers: [
              ["token", window.localStorage.getItem("token")]
            ],
            credentials: 'include'
          },
          history,
          (response: any) => {
            console.log(response);
            if (response) {
              setTextToast("Cadastro realizado com sucesso.");
              setShowToast(true);


              setListaCategoriaProdutos(response.message.Attributes.categoriaProdutos || []);
              dadosEmpresa.categoriaProdutos = response.message.Attributes.categoriaProdutos;
              setDadosEmpresa(dadosEmpresa);

              //carregarConta();
              setShowDetalhes(false);

            } else if (response.status === 400) {
              console.log(response);
              setTextToast("Ocorreu um erro ao efetuar o cadastro.");
              setShowToast(true);

            }
            setShowLoading(false);
          },
          (error: any) => {
            console.log(error);
            setTextToast("Ocorreu um erro ao efetuar o cadastro.");
            setShowToast(true);
            setShowLoading(false);
          })



      } catch (error) {
        console.log("erro", error);
      }
    }

    await atualizar().finally(() => { setShowLoading(false) });
  }

  function handleReorder(event: CustomEvent<ItemReorderEventDetail>) {
    // Before complete is called with the items they will remain in the
    // order before the drag
    //console.log('Before complete', listaItemCatalogoProdutos);

    // Finish the reorder and position the item in the DOM based on
    // where the gesture ended. Update the items variable to the
    // new order of items
    setListaCategoriaProdutos(event.detail.complete(listaCategoriaProdutos));

    // After complete is called the items will be in the new order
    //console.log('After complete', listaItemCatalogoProdutos);


    var token: string = "";
    var tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") { token = tkn; }

    const ordItemCategoriaProdutos = async () => {
      const resp = await fetch(ordernarItemCategoriaProdutosEmpresa.url.replace("{id}", loja.replace(/#/g, "%23")),
        {
          method: ordernarItemCategoriaProdutosEmpresa.method,
          headers: [
            ["token", token]
          ],
          body: JSON.stringify({ 'categoriaProdutos': listaCategoriaProdutos }),
          credentials: 'include'
        });
      const retorno = await resp.json();
      console.log("retorno ordenar", retorno);

      if (retorno && retorno.Item?.Attributes) {
        setTextToast("Ordenação alterada com sucesso.");
        setShowToast(true);
        setListaCategoriaProdutos(retorno.Item?.Attributes?.categoriaProdutos || []);
        dadosEmpresa.categoriaProdutos = retorno.Item?.Attributes?.categoriaProdutos;
        setDadosEmpresa(dadosEmpresa);

      }
      else {
        setTextToast("Ocorreu um erro ao ordernar.");
        setShowToast(true);
      }
    }

    ordItemCategoriaProdutos();

  }


  const loadCategoriaProdutos = () => {

    
    if (listaCategoriaProdutos == undefined) return;

    if (listaCategoriaProdutos.length === 0) {
      return (

        <IonItem lines="full" key={"semAgenda"}>

          <IonLabel>
            <h2 style={{ "verticalAlign": "middle" }}><strong>Nenhuma categoria cadastrada</strong></h2>
          </IonLabel>

        </IonItem>


      );
    }



    return (
      <>
        {/* The reorder gesture is disabled by default, enable it to drag and drop items */}
        <IonReorderGroup disabled={isDisabled} onIonItemReorder={handleReorder}>
          {listaCategoriaProdutos.map((value: any, index: any) => (
            <IonItem lines="full" key={index}>
              <IonLabel style={{ fontSize: "12px", maxWidth: "60px" }}>
                <img id="imagem" style={{ objectFit: 'cover', width: "50px", height: "50px", borderRadius: '5px' }} src={value.urlImagem || imgDefaultImage}></img>
              </IonLabel>

              {/* <IonLabel style={{ fontSize: "12px" }}>{value.codigo}</IonLabel> */}
              <IonLabel style={{ fontSize: "12px" }}>{value.nome}</IonLabel>
              <IonLabel style={{ fontSize: "12px" }}>{value.tipo}</IonLabel>
              <IonLabel style={{ fontSize: "12px", maxWidth: "100px" }}></IonLabel>
              <div style={{ position: "absolute", right: "10px", zIndex: 9999999, backgroundColor: "#ffffff", textAlign: "right" }}>
                <IonReorder slot="end"></IonReorder>
              </div>

              <div style={{ position: "absolute", right: "6px", zIndex: 9999999, backgroundColor: "#ffffff", textAlign: "right" }}>
                <IonButtons>
                  <IonButton hidden={!isDisabled || acessos?.configuracoes != 'edicao'}
                    onClick={() => {
                      setEtapa(2);
                      setIndex(index);
                      setTipo(value.tipo);
                      setNome(value.nome);
                      setFileArquivos(null);
                      setFileDataURL(null);

                      console.log("value", value);
                      if (value.urlImagem) {
                        setFileDataURL(value.urlImagem);
                      }
                      setSizes(value.tamanhos);
                      setMassas(value.massas);
                      setBordas(value.bordas);
                      setShowDetalhes(true);

                    }}>
                    <IonIcon color="primary" icon={pencil} />
                  </IonButton>


                  <IonButton hidden={!isDisabled || acessos?.configuracoes != 'edicao'}
                    onClick={() => {
                      mostartAlerta({
                        backdropDismiss: false,
                        header: 'Remover item',
                        message: 'Deseja <strong>remover</strong>?',
                        buttons: [
                          {
                            text: 'Cancelar',
                            role: 'cancel',
                          },
                          {
                            text: 'Confirmar',
                            cssClass: 'primary',
                            handler: () => {
                              deletarCategoria(index);
                            }
                          }
                        ],
                        onDidDismiss: (e) => console.log('did dismiss'),
                      })
                    }}>
                    <IonIcon color="primary" icon={trash} />
                  </IonButton>

                </IonButtons>


              </div>

            </IonItem>
          ))}
        </IonReorderGroup>
      </>
    );
  }


  const validate = (sizes: Array<{ name: string; sabores: number }>) => {
    let isValid = true;
    let errors: Array<{ message: string }> = [];

    if (!nome) {
      isValid = false;
      errors.push({ message: `Nome não pode estar vazio.` });
    }

    sizes?.forEach((size, index) => {
      if (!size.name) {
        isValid = false;
        errors.push({ message: `Nome do tamanho no item ${index + 1} não pode estar vazio.` });
      }

      if (size.sabores <= 0) {
        isValid = false;
        errors.push({ message: `Qtd. de sabores no item ${index + 1} não foi selecionado.` });
      }
    });

    // Validação das massas
    massas?.forEach((massa, index) => {
      if (!massa.name) {
        isValid = false;
        errors.push({ message: `Nome da massa no item ${index + 1} não pode estar vazio.` });
      }

      if (!massa.price) {
        isValid = false;
        errors.push({ message: `Preço da massa no item ${index + 1} não pode estar vazio.` });
      }
    });

    // Validação das bordas
    bordas?.forEach((borda, index) => {
      if (!borda.name) {
        isValid = false;
        errors.push({ message: `Nome da borda no item ${index + 1} não pode estar vazio.` });
      }

      if (!borda.price) {
        isValid = false;
        errors.push({ message: `Preço da borda no item ${index + 1} não pode estar vazio` });
      }
    });


    return { isValid, errors };
  };


  async function salvar() {

    const { isValid, errors } = validate(sizes);

    if (!isValid && errors.length > 0) {
      console.log('Erros de validação:', errors);

      // Mapeia o array de erros e retorna um array de strings para o IonToast
      const errorMessages: any = errors.map((error) => `${error.message}`);
      setTextToast(errorMessages.join('<br>'));
      setShowToast(true);
      return;
    }


    await assinaUploadS3ArquivoESalvar(fileArquivos);


  }


  const addSize = () => {
    setSizes([...sizes, { name: '', sabores: 0 }]);
  };

  const updateSize = (index: number, field: string, value: any) => {
    const newSizes: any = [...sizes];
    newSizes[index][field] = value;
    setSizes(newSizes);
  };

  const removeSize = (index: number) => {
    const newSizes = sizes.filter((_, i) => i !== index);
    setSizes(newSizes);
  };


  const addMassa = () => {
    setMassas([...massas, { name: '', price: 0 }]);
  };

  const updateMassa = (index: number, field: string, value: any) => {
    const newMassas: any = [...massas];
    newMassas[index][field] = value;
    setMassas(newMassas);
  };

  const removeMassa = (index: number) => {
    const newMassas = massas.filter((_, i) => i !== index);
    setMassas(newMassas);
  };



  const addBorda = () => {
    setBordas([...bordas, { name: '', price: 0 }]);
  };

  const updateBorda = (index: number, field: string, value: any) => {
    const newBordas: any = [...bordas];
    newBordas[index][field] = value;
    setBordas(newBordas);
  };

  const removeBorda = (index: number) => {
    const newBordas = bordas.filter((_, i) => i !== index);
    setBordas(newBordas);
  };


  function avancarEtapa2(tipo: any) {

    if (tipo) {
      setEtapa(2);
      if (inputRef.current) {
        inputRef.current.setFocus();
      }


    }
    else {
      setTextToast("Selecione as opções para avançar.");
      setShowToast(true);
    }
  }

  // Função para atualizar o valor do input no índice específico
  const onChangeValorMassaArray = (e: any, index: number) => {
    e.preventDefault();

    let v = e.target.value;

    v = v.replace(/\d{9}/, '');
    v = v.replace(/\D/g, '');
    v = v.replace(/(\d{1})(\d{1,2})$/, "$1,$2");
    v = v.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    v = v.replace(/^(\d)/g, "$1");

    const newValues = [...massas];
    newValues[index] = { ...newValues[index], price: v }; // Corrigido para não substituir o objeto
    setMassas(newValues);
  };


  // Função para atualizar o valor do input no índice específico
  const onChangeValorBordaArray = (e: any, index: number) => {
    e.preventDefault();

    let v = e.target.value;

    v = v.replace(/\d{9}/, '');
    v = v.replace(/\D/g, '');
    v = v.replace(/(\d{1})(\d{1,2})$/, "$1,$2");
    v = v.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    v = v.replace(/^(\d)/g, "$1");

    const newValues = [...bordas];
    newValues[index] = { ...newValues[index], price: v }; // Corrigido para não substituir o objeto
    setBordas(newValues);
  };

  // Metodo chamado quando é selecionado um arquivo para subida
  async function ChangeHandlerInputFile(e: any) {
    const file = e.target.files[0];
    if (file) {
      if (!file.type.match(imageMimeType)) {
        alert("Tipo da imagem inválido");
        return;
      }


      // if (fileArquivos && fileArquivos.size > 100000) {
      const fotoReduzida = await ReduzirFoto(file);
      console.log("Tamanho comprimido:", fotoReduzida.size);
      setFileArquivos(fotoReduzida);
      // }
      // else {
      //   setFileArquivos(file);
      // }




    }
    else {
      alert("Arquivo não selecionado");
      return;
    }
  }

  async function ReduzirFoto(file: File) {
    const options = {
      maxSizeMB: 0.05, // Reduzindo o tamanho máximo para 0.05 MB (50 KB)
      maxWidthOrHeight: 400, // Reduzindo a dimensão máxima para 400px
      useWebWorker: true,
      initialQuality: 0.5 // Reduzindo a qualidade inicial para 30%
    }

    return await imageCompression(file, options);
  }


  return (

    <IonPage className="ProdutosCategoriasNovo">
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={() => { history.goBack();/*.replace("/empresa/operacoes", { recarregar: false }); */ }}>
              <IonIcon slot="icon-only" icon={arrowBack} />
            </IonButton>
          </IonButtons>
          <IonTitle>Categorias dos produtos</IonTitle>
        </IonToolbar>
        <IonToolbar>
          <IonList>
            <IonItem lines="none" style={{ fontWeight: 'bold', borderBottom: '1px solid #dddddd' }}>
              <IonLabel style={{ fontSize: "12px", maxWidth: "60px" }}></IonLabel>
              <IonLabel style={{ fontSize: "12px" }}>Nome</IonLabel>
              <IonLabel style={{ fontSize: "12px" }}>Tipo</IonLabel>

              <IonLabel style={{ textAlign: "right", maxWidth: "100px" }}>
                <IonBadge hidden={acessos?.configuracoes != 'edicao'}
                  onClick={() => setIsDisabled((current) => !current)}>
                  <IonIcon color="medium" icon={reorderTwo}></IonIcon>
                </IonBadge>
              </IonLabel>
            </IonItem>
          </IonList>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen={false}>
        <IonFab vertical="bottom" horizontal="center" slot="fixed" hidden={acessos?.configuracoes != 'edicao'}>
          <IonFabButton color="tertiary" class="custom-fab-button" onClick={() => {
            setIndex("");
            setNome("");
            setTipo("");

            setFileArquivos(null);
            setFileDataURL(null);

            setEtapa(1);
            setSizes([]);
            setMassas([]);
            setBordas([]);
            setShowDetalhes(true);
          }} >
            <IonIcon color="light" icon={add}></IonIcon>
          </IonFabButton>
        </IonFab>

        <IonList>
          {loadCategoriaProdutos()}

        </IonList>

        <IonModal className="fullscreen-modal" onDidPresent={() => {
          if (inputRef.current) {
            inputRef.current.setFocus();
          }
        }} isOpen={showDetalhes} onDidDismiss={() => {
          setShowDetalhes(false);
          setIndex("");
          setNome("");
          setTipo("");
          setEtapa(1);
          setSizes([]);
          setMassas([]);
          setBordas([]);
        }}>


          <>
            <IonHeader>
              <IonToolbar color="primary">
                <IonButtons slot="start">
                  <IonIcon slot="icon-only" icon={arrowBack} onClick={() => setShowDetalhes(false)} />
                </IonButtons>
                <IonTitle>Nova categoria</IonTitle>
              </IonToolbar>
            </IonHeader>
            <IonContent>


              <IonGrid hidden={etapa != 1}>


                <IonRow className="ion-justify-content-center">
                  <IonCol size="auto">
                    <div style={{ padding: '10px', textAlign: 'center' }}>
                      <p>Selecione o modelo de categoria para dividir o seu cardápio</p>
                    </div>
                  </IonCol>
                </IonRow>

                <IonRow className="ion-justify-content-center">

                  <IonCol size="auto">
                    <IonButton
                      onClick={() => {
                        setTipo('simples');
                        avancarEtapa2('simples');
                      }}
                      color={tipo === 'simples' ? 'danger' : 'light'}
                      className="categorias-pizzas-flat-button-size"
                    >
                      <div style={{ textAlign: 'center' }}>
                        <IonIcon icon={restaurantOutline} style={{ fontSize: '80px' }} />
                        <div style={{ marginTop: '10px', textAlign: 'center' }}>
                          <div style={{ fontWeight: 'bold' }}>Itens</div>
                          <div style={{
                            fontSize: '10px', marginTop: '6px', wordWrap: 'break-word', // Ensure text wraps inside the container
                            overflowWrap: 'break-word', // Fallback for older browsers
                            textAlign: 'center'
                          }}>Defina bebidas, pratos, lanches, sobremesas, etc.</div>
                        </div>
                      </div>
                    </IonButton>

                  </IonCol>

                  <IonCol size="auto">
                    <IonButton
                      onClick={() => {
                        setTipo('complementos');
                        avancarEtapa2('complementos');
                      }}
                      color={tipo === 'complementos' ? 'danger' : 'light'}
                      className="categorias-pizzas-flat-button-size"
                    >
                      <div style={{ textAlign: 'center' }}>
                        <IonIcon icon={fastFoodOutline} style={{ fontSize: '80px' }} />
                        <div style={{ marginTop: '10px', textAlign: 'center' }}>
                          <div style={{ fontWeight: 'bold' }}>Complementos</div>
                          <div style={{
                            fontSize: '10px', marginTop: '6px', wordWrap: 'break-word', // Ensure text wraps inside the container
                            overflowWrap: 'break-word', // Fallback for older browsers
                            textAlign: 'center'
                          }}>Defina complementos para combos.</div>
                        </div>
                      </div>
                    </IonButton>

                  </IonCol>

                  <IonCol size="auto">
                    <IonButton
                      onClick={() => {
                        setTipo('pizzas');
                        avancarEtapa2('pizzas');
                      }}
                      color={tipo === 'pizzas' ? 'danger' : 'light'}
                      // shape="round"
                      className="categorias-pizzas-flat-button-size"
                    >
                      <div style={{ textAlign: 'center' }}>
                        <IonIcon icon={pizzaOutline} style={{ fontSize: '80px' }} />
                        <div style={{ marginTop: '10px', textAlign: 'center' }}>
                          <div style={{ fontWeight: 'bold' }}>Pizzas</div>
                          <div style={{
                            fontSize: '10px', marginTop: '6px', wordWrap: 'break-word', // Ensure text wraps inside the container
                            overflowWrap: 'break-word', // Fallback for older browsers
                            textAlign: 'center'
                          }}>Defina o tamanho e quantidade de sabores das pizzas</div>
                        </div>
                      </div>


                    </IonButton>
                  </IonCol>




                </IonRow>

              </IonGrid>








              <IonGrid hidden={etapa != 2}>
                <IonRow>
                  <IonCol>
                    <IonList>

                      <IonItem lines="none">
                        <IonLabel position="stacked">Nome*</IonLabel>
                        <IonInput placeholder="Digite o nome da categoria" value={nome} ref={(ref) => inputRef.current = ref} autoCorrect='true' autoCapitalize='true' required onIonInput={(e: any) => setNome(e.target.value)}></IonInput>
                      </IonItem>
                    </IonList>
                  </IonCol>
                </IonRow>


                <IonRow>
                  <IonCol>
                    <IonList>
                      <IonItem lines="none">

                        <IonLabel position="stacked" style={{ marginBottom: '10px' }}>Imagem</IonLabel>


                        <IonItem lines='none'>
                          <input onChange={ChangeHandlerInputFile} type="file" accept="image/*" />
                        </IonItem>
                        <img id="imagem" style={{ objectFit: 'cover', width: "180px", height: "180px", borderRadius: '15px' }} src={fileDataURL || imgDefaultImage}></img>

                      </IonItem>
                    </IonList>
                  </IonCol>
                </IonRow>


                <IonGrid>
                  <IonRow className="ion-justify-content-center" hidden={!(tipo == "pizzas")}>
                    <IonCol size="auto">
                      <div style={{ padding: '10px', textAlign: 'center' }}>
                        <p>Tamanhos</p>
                      </div>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonButton fill="clear" color="primary" onClick={addSize} hidden={!(tipo == "pizzas")}>
                        + Adicionar novo tamanho
                      </IonButton>
                    </IonCol>
                  </IonRow>
                  {sizes?.map((size, index) => (
                    <IonCard key={index}>
                      <IonCardContent>
                        <IonGrid style={{ padding: "0px", margin: "0px" }}>
                          <IonRow>
                            <IonCol size="10" style={{ padding: "0px", margin: "0px" }}>
                              <IonItem lines="none">
                                <IonLabel position="stacked">Nome do tamanho*</IonLabel>
                                <IonInput
                                  value={size.name}
                                  placeholder="Ex: Grande"
                                  onIonInput={(e: any) => updateSize(index, 'name', e.target.value)}
                                />
                              </IonItem>
                            </IonCol>
                            <IonCol size="2" style={{ padding: "0px", margin: "0px", textAlign: "right" }}>
                              <IonButton fill="clear" color="danger" onClick={() => removeSize(index)}>
                                <IonIcon icon={trashOutline} />
                              </IonButton>
                            </IonCol>
                            <IonCol size="12" style={{ padding: "0px", margin: "0px" }}>
                              <IonItem lines="none">
                                <IonLabel position="stacked">Qtd. Sabores*</IonLabel>
                                <IonGrid>
                                  <IonRow>
                                    {[1, 2, 3].map((num) => (
                                      <IonCol key={num} style={{ padding: "0px", margin: "0px" }}>
                                        <IonButton
                                          color={size.sabores === num ? "danger" : "light"}
                                          onClick={() => updateSize(index, 'sabores', num)}
                                        >
                                          {num}
                                        </IonButton>
                                      </IonCol>
                                    ))}
                                  </IonRow>
                                </IonGrid>
                              </IonItem>
                            </IonCol>

                          </IonRow>
                        </IonGrid>
                      </IonCardContent>
                    </IonCard>
                  ))}
                </IonGrid>

                <IonGrid>
                  <IonRow className="ion-justify-content-center" hidden={!(tipo == "pizzas")}>
                    <IonCol size="auto" >
                      <div style={{ padding: '10px', textAlign: 'center' }}>
                        <p>Massas</p>
                      </div>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonButton fill="clear" color="primary" onClick={addMassa} hidden={!(tipo == "pizzas")}>
                        + Adicionar massa
                      </IonButton>
                    </IonCol>
                  </IonRow>
                  {massas?.map((massa, index) => (
                    <IonCard key={index}>
                      <IonCardContent>
                        <IonGrid>
                          <IonRow>
                            <IonCol size="10" style={{ padding: "0px", margin: "0px" }}>
                              <IonItem lines="none">
                                <IonLabel position="stacked">Nome da massa*</IonLabel>
                                <IonInput
                                  value={massa.name}
                                  placeholder="Ex: Massa tradicional"
                                  onIonInput={(e: any) => updateMassa(index, 'name', e.target.value)}
                                />
                              </IonItem>
                            </IonCol>
                            <IonCol size="2" style={{ padding: "0px", margin: "0px", textAlign: "right" }}>
                              <IonButton fill="clear" color="danger" onClick={() => removeMassa(index)}>
                                <IonIcon icon={trashOutline} />
                              </IonButton>
                            </IonCol>
                            <IonCol size="12" style={{ padding: "0px", margin: "0px" }}>
                              <IonItem lines="none">
                                <IonLabel position="stacked">Preço*</IonLabel>
                                <IonInput
                                  type="tel"
                                  maxlength={14}
                                  placeholder="Digite o valor"
                                  value={massa.price || ''}
                                  onIonChange={(e: any) => onChangeValorMassaArray(e, index)}
                                />
                              </IonItem>
                            </IonCol>

                          </IonRow>
                        </IonGrid>
                      </IonCardContent>
                    </IonCard>
                  ))}
                </IonGrid>

                <IonGrid>
                  <IonRow className="ion-justify-content-center" hidden={!(tipo == "pizzas")}>
                    <IonCol size="auto">
                      <div style={{ padding: '10px', textAlign: 'center' }}>
                        <p>Bordas</p>
                      </div>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonButton fill="clear" color="primary" onClick={addBorda} hidden={!(tipo == "pizzas")}>
                        + Adicionar borda
                      </IonButton>
                    </IonCol>
                  </IonRow>
                  {bordas?.map((borda, index) => (
                    <IonCard key={index}>
                      <IonCardContent>
                        <IonGrid>
                          <IonRow>
                            <IonCol size="10" style={{ padding: "0px", margin: "0px" }}>
                              <IonItem lines="none">
                                <IonLabel position="stacked">Nome da borda*</IonLabel>
                                <IonInput
                                  value={borda.name}
                                  placeholder="Ex: Borda catupiry"
                                  onIonInput={(e: any) => updateBorda(index, 'name', e.target.value)}
                                />
                              </IonItem>
                            </IonCol>
                            <IonCol size="2" style={{ padding: "0px", margin: "0px", textAlign: "right" }}>
                              <IonButton fill="clear" color="danger" onClick={() => removeBorda(index)}>
                                <IonIcon icon={trashOutline} />
                              </IonButton>
                            </IonCol>
                            <IonCol size="12" style={{ padding: "0px", margin: "0px" }}>
                              <IonItem lines="none">
                                <IonLabel position="stacked">Preço*</IonLabel>
                                <IonInput
                                  type="tel"
                                  maxlength={14}
                                  placeholder="Digite o valor"
                                  value={borda.price || ''}
                                  onIonChange={(e: any) => onChangeValorBordaArray(e, index)}
                                />
                              </IonItem>
                            </IonCol>

                          </IonRow>
                        </IonGrid>
                      </IonCardContent>
                    </IonCard>
                  ))}
                </IonGrid>
              </IonGrid>
            </IonContent>
            {/* <IonFooter hidden={etapa != 1} className="ion-no-border">

              <IonToolbar>
                <IonButton hidden={etapa != 1}
                  onClick={async () => {
                    avancarEtapa2();
                  }}
                  expand="block"
                  type="submit"
                  style={{ margin: "0px", textAlign: "right" }}
                >
                  Avançar
                </IonButton>
              </IonToolbar>
            </IonFooter> */}

            <IonFooter hidden={etapa != 2} className="ion-no-border">
              <IonToolbar>
                <IonButton onClick={() => salvar()} expand="block" type="submit">Salvar</IonButton>
              </IonToolbar>
            </IonFooter>
          </>

        </IonModal>

        <IonToast
          isOpen={showToast}
          position="middle" //top, bottom, middle
          onDidDismiss={() => setShowToast(false)}
          message={textToast}
          duration={3000}
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => { setShowLoading(false); }}
          spinner='circles'
        />

      </IonContent>

    </IonPage>
  );


};

export default ProdutosCategoriasNovo;