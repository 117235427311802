import { AppLauncher } from '@capacitor/app-launcher';
import { IonButton, IonButtons, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonInput, IonLabel, IonLoading, IonRow, IonTitle, IonToast, IonToolbar, useIonAlert } from '@ionic/react';
import { arrowBack, printOutline, trashOutline } from 'ionicons/icons';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { MessageContext } from '../../../../../contexts/MessageContext';
import { alterarStatusProjeto, apiMercadoPagoOperationCriarPagamento, fecharMesa, requestService, urlBase } from '../../../../../Utils/Services';
import "./ComandasFechar.css";
import iconMercadopago from "./imgs/mercadopagoIcon.svg";

type Props = {
  projetoid: string;
  imprimir: Function;
  closeAction: Function;
  closeActionSimple: Function;
};

const ComandasFechar: React.FC<Props> = (props: Props) => {

  const history = useHistory<any>();
  const [showLoading, setShowLoading] = useState(false);
  const [dadosProjeto, setDadosProjeto] = useState<any>([]);
  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");
  const buscarInputRef = useRef<any>(null);
  const [troco, setTroco] = useState<any>();
  const [taxaEntrega, setTaxaEntrega] = useState<any>();
  // const [formaPagamento, setFormaPagamento] = useState('');

  const { dadosEmpresa, setDadosEmpresa } = useContext(MessageContext);

  const [mostrarAlerta] = useIonAlert();

  const { showPagamentoMaquininha, setShowPagamentoMaquininha } = useContext(MessageContext);
  const { idPagamentoMaquininha, setIdPagamentoMaquininha } = useContext(MessageContext);
  // const { qrCodePagamentoMaquininha, setQrCodePagamentoMaquininha } = useContext(MessageContext);

  const [divididoPor, setDivididoPor] = useState<any>(); // Array para armazenar campos dinâmicos
  const [arrayFormaPagamento, setArrayFormaPagamento] = useState<string[]>([]); // Array para armazenar campos dinâmicos

  const { idPaymentSuccess, setIdPaymentSuccess } = useContext(MessageContext);
  const { idPaymentMaquininhaSuccess, setIdPaymentMaquininhaSuccess } = useContext(MessageContext);

  // const opcoesPagamento = [
  //   'Dinheiro',
  //   'Cartão de Crédito',
  //   'Cartão de Débito',
  //   'Pix',
  //   'Alelo',
  //   'Sodexo',
  //   'Vale Alimentação',
  //   // 'Mercado Pago Crédito', 'Mercado Pago Débito', 'Mercado Pago Alelo', 'Mercado Pago Sodexo', 'InfinitePay Crédito', 'InfinitePay Débito'
  // ];

  const [desconto, setDesconto] = useState('');

  const opcoesDesconto = [
    { label: 'R$ 0,00', value: '0,00' },
    { label: 'R$ 5,00', value: '5,00' },
    { label: 'R$ 10,00', value: '10,00' },
    { label: 'R$ 15,00', value: '15,00' },
    { label: 'R$ 20,00', value: '20,00' },
    { label: 'R$ 25,00', value: '25,00' }
  ];

  const [taxaServico, setTaxaServico] = useState('');

  const opcoesTaxaServico = [
    { label: '0%', value: '0' },
    { label: '10%', value: '10' },
    { label: '12%', value: '12' },
    { label: '15%', value: '15' }
  ];

  const opcoesTaxaEntrega = [
    { label: 'Grátis', value: '0,00' },
    { label: 'R$ 3,00', value: '3,00' },
    { label: 'R$ 4,00', value: '4,00' },
    { label: 'R$ 5,00', value: '5,00' },
    { label: 'R$ 6,00', value: '6,00' },
    { label: 'R$ 7,00', value: '7,00' },
    { label: 'R$ 8,00', value: '8,00' },
    { label: 'R$ 10,00', value: '10,00' },
    { label: 'R$ 12,00', value: '12,00' },
    { label: 'R$ 15,00', value: '15,00' },
  ];

  const [valorTotalProdutos, setValorTotalProdutos] = useState<any>();
  const [valorTotal, setValorTotal] = useState<any>();

  useEffect(() => {
    if (props.projetoid != "") {
      console.log("entrou no useEffect");
      carregarProjeto();
      //alterar(formaPagamento, taxaServico, taxaEntrega, desconto);
    }
  }, []);


  useEffect(() => {
    if (!idPaymentMaquininhaSuccess) return;

    console.log("Entrou no useEffect para atualizar forma de pagamento no retorno do mercado pago");

    // Atualiza a forma de pagamento e reseta o id
    handleAtualizarFormaPagamentoMaquininha(idPaymentMaquininhaSuccess);
    setIdPaymentMaquininhaSuccess(""); // Reseta o ID após o processamento
  }, [idPaymentMaquininhaSuccess]);

  const handleAtualizarFormaPagamentoMaquininha = async (id: any) => {
    // Encontra o objeto no array com o ID correspondente
    const pagamentoSelecionado: any = arrayFormaPagamento.find((pagamento: any) => pagamento.id === id);

    if (!pagamentoSelecionado) {
      setTextToast("************* callback - Pagamento não encontrado.");
      setShowToast(true);
      return;
    }

    // Marca o pagamento como efetuado
    pagamentoSelecionado.pagou = true;

    const valorTotalNumerico = parseFloat(valorTotal?.replace(/\./g, '').replace(/\,/g, '.'));
    const somaValores = somaValoresPagos();
    const valorRestante = valorTotalNumerico - somaValores;

    console.log("valorRestante", valorRestante);

    if (valorRestante === 0) {
      console.log("finalizou os pagamentos");
      return fechar().then(() => {
        console.log("dadosEmpresa.impressaoAutomatica", dadosEmpresa.impressaoAutomatica);
        if (dadosEmpresa.impressaoAutomatica) {
          props.imprimir() // Aguarda a execução de imprimir após alterar
            .then(() => {
              console.log("Impressão bem-sucedida");
            })
            .catch((error: any) => {
              console.log("Erro ao imprimir:", error.message);
              setTextToast(error.message);
              setShowToast(true);
              return;
            })
        }
        props.closeAction();
        console.log("Mesa fechada com sucesso");
      });
    }

    return Promise.resolve();
  };

  useEffect(() => {
    if (!idPaymentSuccess) return;

    console.log("Entrou no useEffect para atualizar forma de pagamento no retorno da infinitepay");

    // Atualiza a forma de pagamento e reseta o id
    handleAtualizarFormaPagamento(idPaymentSuccess);
    setIdPaymentSuccess(""); // Reseta o ID após o processamento
  }, [idPaymentSuccess]);

  const handleAtualizarFormaPagamento = async (id: any) => {
    // Encontra o objeto no array com o ID correspondente
    const pagamentoSelecionado: any = arrayFormaPagamento.find((pagamento: any) => pagamento.id === id);

    if (!pagamentoSelecionado) {
      setTextToast("************* callback - Pagamento não encontrado.");
      setShowToast(true);
      return;
    }

    // Marca o pagamento como efetuado
    pagamentoSelecionado.pagou = true;

    setShowLoading(true);

    alterar()
      .then((response) => {
        console.log("Alteração bem-sucedida retorno pagamento infinitePay", response);

        const valorTotalNumerico = parseFloat(valorTotal?.replace(/\./g, '').replace(/\,/g, '.'));
        const somaValores = somaValoresPagos();
        const valorRestante = valorTotalNumerico - somaValores;

        console.log("valorRestante", valorRestante);

        if (valorRestante === 0) {
          console.log("finalizou os pagamentos");
          return fechar().then(() => {
            console.log("dadosEmpresa.impressaoAutomatica", dadosEmpresa.impressaoAutomatica);
            if (dadosEmpresa.impressaoAutomatica) {
              props.imprimir() // Aguarda a execução de imprimir após alterar
                .then(() => {
                  console.log("Impressão bem-sucedida");
                })
                .catch((error: any) => {
                  console.log("Erro ao imprimir:", error.message);
                  setTextToast(error.message);
                  setShowToast(true);
                  return;
                })
            }
            props.closeAction();
            console.log("Mesa fechada com sucesso");
          });
        }

        return Promise.resolve();
      })
      .catch((error) => {
        console.error("Erro:", error.message);
        setTextToast(error.message);
        setShowToast(true);
      })
      .finally(() => {
        setShowLoading(false);
      });

  };

  const somaValoresPagos = () =>
    arrayFormaPagamento.reduce((total: any, campo: any) => {
      // if (campo.pagou) {
      return total + (parseFloat(campo.valor?.replace(/\./g, '').replace(/\,/g, '.')) || 0);
      // }
      // return total;
    }, 0);


  useEffect(() => {

    try {
      let taxaServicoFloat = parseFloat(taxaServico?.replace(/\./g, '').replace(/\,/g, '.'));
      let descontoFloat = parseFloat(desconto?.replace(/\./g, '').replace(/\,/g, '.'));
      let taxaEntregaFloat = parseFloat(taxaEntrega?.replace(/\./g, '').replace(/\,/g, '.'));

      let valorTotalRecalculado = 0;
      if (valorTotalProdutos) {
        valorTotalRecalculado = parseFloat(valorTotalProdutos.replace(/\./g, '').replace(/\,/g, '.'));
      }

      if (taxaEntrega) {
        valorTotalRecalculado += (taxaEntregaFloat ? taxaEntregaFloat : 0);
      }

      if (desconto) {
        valorTotalRecalculado += - (descontoFloat ? descontoFloat : 0);
      }
      if (taxaServico) {
        valorTotalRecalculado += (valorTotalRecalculado * (taxaServicoFloat / 100));
      }

      setValorTotal(valorTotalRecalculado.toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }));

    } catch (error) {
      console.log("erro ao calcular", error);
    }
  }, [taxaEntrega, taxaServico, desconto, valorTotalProdutos]);



  // useEffect(() => {
  //   if (formaPagamento) {
  //     alterar(formaPagamento, taxaServico, taxaEntrega, desconto);
  //   }
  // }, [formaPagamento]);



  // useEffect(() => {
  //   if (desconto) {
  //     alterar(formaPagamento, taxaServico, taxaEntrega, desconto);
  //   }
  // }, [desconto]);


  // useEffect(() => {
  //   if (taxaServico) {
  //     alterar(formaPagamento, taxaServico, taxaEntrega, desconto);
  //   }
  // }, [taxaServico]);



  function carregarProjeto() {

    const consultar = async () => {

      var token: string = "";
      var tkn = localStorage.getItem("token");
      if (typeof (tkn) === "string") { token = tkn; }
      setShowLoading(true);
      const resp = await fetch(urlBase.url + "/projetos/" + props.projetoid.replaceAll(/#/g, '%23'),
        {
          method: urlBase.method,
          headers: [
            ["token", token]
          ],
          credentials: 'include'
        }).finally(() => {
          setShowLoading(false);
        });
      const response = await resp.json();

      if (response.Item) {

        if (response.Item.formaPagamento && Array.isArray(response.Item.formaPagamento)) {
          setArrayFormaPagamento([...response.Item.formaPagamento]);
        }

        setDesconto(response.Item.desconto);
        setTaxaServico(response.Item.taxaServico);

        if (response.Item.valorTotalProdutos) {
          setValorTotalProdutos(response.Item.valorTotalProdutos?.toLocaleString('pt-br', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }));
        }
        else {
          setValorTotalProdutos("0,00");
        }

        if (response.Item.valorTotal) {
          setValorTotal(response.Item.valorTotal?.toLocaleString('pt-br', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }));
        }
        else {
          setValorTotal("0,00");
        }

      }

      if (buscarInputRef.current) {
        buscarInputRef.current.setFocus();
      }
    }

    consultar();
  }


  // function preencherProjeto(response: any) {

  //   setDadosProjeto(response.Item || []);


  //   if (response.Item.formaPagamento && Array.isArray(response.Item.formaPagamento)) {
  //     setArrayFormaPagamento([...response.Item.formaPagamento]);
  //   }


  //   setFormaPagamento(response.formaPagamento);
  //   setTaxaServico(response.taxaServico);
  //   setDesconto(response.desconto);
  //   setTaxaEntrega(response.taxaEntrega);

  //   if (response.valorTotalProdutos) {
  //     setValorTotalProdutos(response.valorTotalProdutos?.toLocaleString('pt-br', {
  //       minimumFractionDigits: 2,
  //       maximumFractionDigits: 2
  //     }));
  //   }
  //   else {
  //     setValorTotalProdutos("0,00");
  //   }

  //   if (response.valorTotal) {
  //     setValorTotal(response.valorTotal?.toLocaleString('pt-br', {
  //       minimumFractionDigits: 2,
  //       maximumFractionDigits: 2
  //     }));
  //   }
  //   else {
  //     setValorTotal("0,00");
  //   }

  // }

  function fechar() {
    return new Promise((resolve, reject) => {
      setShowLoading(true);

      requestService(
        fecharMesa.url.replaceAll("{id}", props.projetoid.replaceAll(/#/g, "%23")),
        {
          method: fecharMesa.method,
          headers: [["token", window.localStorage.getItem("token")]],
          credentials: "include",
        },
        history,
        async (response: any) => {
          setShowLoading(false);

          if (response) {
            // atualiza estoque
            // if (response.data) {
            //   setDadosEmpresa(response.data);
            // }
            resolve(response);
          } else {
            reject(new Error("Resposta inválida do servidor."));
          }
        },
        (error: any) => {
          console.log(error);
          setShowLoading(false);
          setTextToast(error.message);
          setShowToast(true);
          reject(error);
        }
      );
    });
  }




  async function alterar(
    // formaPagamento: any,
    // taxaServico: any,
    // taxaEntrega: any,
    // desconto: any
  ) {
    return new Promise((resolve, reject) => {
      const objectRequest = {
        formaPagamento: arrayFormaPagamento, //formaPagamento,
        troco: calcularValorTroco(valorTotal, arrayFormaPagamento),
        taxaEntrega: taxaEntrega,
        taxaServico: taxaServico,
        desconto: desconto,
      };

      requestService(
        alterarStatusProjeto.url.replaceAll("{id}", props.projetoid.replaceAll(/#/g, "%23")) + "?alteracao=true",
        {
          method: alterarStatusProjeto.method,
          headers: [["token", localStorage.getItem("token") || ""]],
          credentials: "include",
          body: JSON.stringify(objectRequest),
        },
        history,
        (response: any) => {
          console.log("response", response);
          // preencherProjeto(response);
          resolve(response); // Resolve a Promise se a alteração for bem-sucedida
        },
        (error: any) => {
          setTextToast(error.message);
          setShowToast(true);
          reject(error); // Rejeita a Promise se houver erro
        }
      );
    });
  }

  async function alterarFormaPagamento(
    arrayFormaPagamentoAtualizada: any
  ) {
    return new Promise((resolve, reject) => {
      const objectRequest = {
        formaPagamento: arrayFormaPagamentoAtualizada, //formaPagamento,
        troco: calcularValorTroco(valorTotal, arrayFormaPagamentoAtualizada),
        taxaEntrega: taxaEntrega,
        taxaServico: taxaServico,
        desconto: desconto,
      };

      requestService(
        alterarStatusProjeto.url.replaceAll("{id}", props.projetoid.replaceAll(/#/g, "%23")) + "?alteracao=true",
        {
          method: alterarStatusProjeto.method,
          headers: [["token", localStorage.getItem("token") || ""]],
          credentials: "include",
          body: JSON.stringify(objectRequest),
        },
        history,
        (response: any) => {
          console.log("response", response);
          // preencherProjeto(response);
          resolve(response); // Resolve a Promise se a alteração for bem-sucedida
        },
        (error: any) => {
          setTextToast(error.message);
          setShowToast(true);
          reject(error); // Rejeita a Promise se houver erro
        }
      );
    });
  }

  // async function imprimir() {
  //   return new Promise<void>((resolve, reject) => {
  //     gerarCupomMesa(props.projetoid)
  //       .then(() => {
  //         resolve();
  //       })
  //       .catch((error) => {
  //         reject(error);
  //       });
  //   });
  // }

  // async function gerarCupomMesa(projetoid: any) {
  //   return new Promise(async (resolve, reject) => {
  //     var token: string = "";
  //     var tkn = localStorage.getItem("token");
  //     if (typeof tkn === "string") {
  //       token = tkn;
  //     }
  //     console.log("projetoid", projetoid);

  //     setShowLoading(true);
  //     let montaUrl = mesaGerarCupom.url.replaceAll("{id}", projetoid.replaceAll(/#/g, "%23"));

  //     try {
  //       const resp = await fetch(montaUrl, {
  //         method: mesaGerarCupom.method,
  //         headers: [["token", token]],
  //         credentials: "include",
  //       });

  //       const result = await resp.json();
  //       if (result) {
  //         console.log("result", result);

  //         mostrarAlerta({
  //           backdropDismiss: false,
  //           header: "Gerar Cupom",
  //           message: "<strong>Cupom</strong> foi gerado com sucesso.",
  //           buttons: [
  //             {
  //               text: "Cancelar",
  //               role: "cancel",
  //             },
  //             {
  //               text: "Visualizar",
  //               cssClass: "primary",
  //               handler: () => {
  //                 window.open(result, "_blank");
  //               },
  //             },
  //           ],
  //           onDidDismiss: (e) => console.log("did dismiss"),
  //         });
  //         resolve(result); // Resolve a Promise se a impressão for bem-sucedida
  //       }
  //     } catch (error: any) {
  //       setTextToast(error.message);
  //       setShowToast(true);
  //       reject(error); // Rejeita a Promise em caso de erro
  //     } finally {
  //       setShowLoading(false);
  //     }
  //   });
  // }

  const onChangeValorArray = (e: any, index: number) => {
    // e.persist();
    e.preventDefault();

    let v = e.target.value;

    v = v.replace(/\d{9}/, '');
    v = v.replace(/\D/g, '');
    v = v.replace(/(\d{1})(\d{1,2})$/, "$1,$2");
    v = v.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    v = v.replace(/^(\d)/g, "$1");





    const novosCampos: any = [...arrayFormaPagamento];
    novosCampos[index].valor = v;
    setArrayFormaPagamento(novosCampos);

  };

  const salvarValor = (valor: string, index: number) => {
    console.log(`Salvando valor: ${valor} no índice: ${index}`);

    alterar()
      .then((response) => {
        console.log("Alteração bem-sucedida", response);
        return Promise.resolve();
      })
      .catch((error) => {
        console.error("Erro:", error.message);
        setTextToast(error.message);
        setShowToast(true);
      });
  };


  function onChangeQuantidadeDivididoPor(e: any) {

    // e.persist();
    e.preventDefault();

    let v = e.target.value;
    v = v.replace(/\D/g, '');

    console.log("v", v);

    setDivididoPor(v);
  }

  // Função para adicionar formas de pagamento
  const handleAdicionarFormaPagamento = (opcao: string) => {
    const valorTotalNumerico = parseFloat(valorTotal?.replace(/\./g, '').replace(/\,/g, '.'));
    const somaValores = arrayFormaPagamento.reduce((total: any, campo: any) => total + (parseFloat(campo.valor?.replace(/\./g, '').replace(/\,/g, '.')) || 0), 0);
    const valorRestante = valorTotalNumerico - somaValores;

    console.log("somaValores", somaValores);
    console.log("valorTotalNumerico", valorTotalNumerico);
    console.log("valorRestante", valorRestante);

    let uuid = crypto.randomUUID();
    console.log("uuid", uuid);

    if (valorRestante > 0) {
      // Adiciona a nova forma de pagamento com um ID único
      const novoPagamento = {
        id: uuid, // Gera um GUID único
        pagamento: opcao,
        valor: valorRestante.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      };

      // Pegando o valor atual do array
      const formaPagamentoAtualizada: any = [...arrayFormaPagamento, novoPagamento];

      // Atualizando o estado
      setArrayFormaPagamento(formaPagamentoAtualizada);
      alterarFormaPagamento(formaPagamentoAtualizada);
    } else {
      console.log('Valor total atingido. Não é possível adicionar mais formas de pagamento.');
    }
  };

  function generateInfinitePayDeeplink(params: {
    amount: number; // Valor em centavos
    paymentMethod: "credit" | "debit";
    installments?: number; // Obrigatório se for crédito
    orderId: string;
    resultUrl: string; // Retorno deeplink
    appClientReferrer: string;
  }): string {
    const { amount, paymentMethod, installments, orderId, resultUrl, appClientReferrer } = params;

    // Validação de regras
    if (amount < 100) {
      throw new Error("O valor mínimo é 1,00 real (100 centavos).");
    }

    if (paymentMethod === "credit") {
      if (!installments || installments < 1 || installments > 12) {
        throw new Error("Número de parcelas deve estar entre 1 e 12.");
      }
      if (amount / installments < 100) {
        throw new Error("Cada parcela deve ser de pelo menos 1,00 real.");
      }
    }

    // Codifica parâmetros para a URL
    const deeplink = `infinitepaydash://infinitetap-app?amount=${amount}&payment_method=${paymentMethod}&${installments ? `installments=${installments}&` : ""
      }order_id=${orderId}&result_url=${encodeURIComponent(resultUrl)}&app_client_referrer=${appClientReferrer}&af_force_deeplink=true`;

    return deeplink;
  }


  async function criarPagamentoInfinitePay(index: number, metodoPagamento: any) {

    const pagamentoSelecionado: any = arrayFormaPagamento[index];

    if (!pagamentoSelecionado) {
      setTextToast("Pagamento não encontrado.");
      setShowToast(true);
      return;
    }

    if (parseFloat(pagamentoSelecionado.valor.replace(/\./g, '').replace(/\,/g, '.')) < 1) {
      setTextToast("Pagamento não pode ser menor que R$ 1,00.");
      setShowToast(true);
      return;
    }

    let amount = pagamentoSelecionado.valor;
    // Adiciona um "0" se houver apenas um dígito após a vírgula
    if (/,\d$/.test(amount)) {
      amount = amount + "0";
    }

    // Adiciona ",00" se não houver vírgula (número inteiro)
    if (!amount.includes(',')) {
      amount = amount + ",00";
    }

    try {
      const deeplink = generateInfinitePayDeeplink({
        amount: parseFloat(amount.replace(/\./g, '').replace(/\,/g, '')), // 1000 --> R$10,00 em centavos
        paymentMethod: metodoPagamento,
        installments: 1, // descarta automaticamente quando for debit
        orderId: pagamentoSelecionado.id,
        //  resultUrl: "xeguei://infinitepay/caixa/produtos?id=" + props.projetoid.replaceAll(/#/g, '%23'),
        resultUrl: "xeguei://infinitepay/",
        appClientReferrer: "Xeguei-Empresa",
      });

      console.log("Deeplink gerado:", deeplink);

      await AppLauncher.openUrl({ url: deeplink });

    } catch (error: any) {
      console.error("Erro ao iniciar transação:", error.message);
    }

  }

  async function criarPagamentoMercadoPago(index: number, opcao: any) {




    const consultar = async () => {


      const pagamentoSelecionado: any = arrayFormaPagamento[index];

      if (!pagamentoSelecionado) {
        setTextToast("Pagamento não encontrado.");
        setShowToast(true);
        return;
      }

      if (parseFloat(pagamentoSelecionado.valor.replace(/\./g, '').replace(/\,/g, '.')) < 1) {
        setTextToast("Pagamento não pode ser menor que R$ 1,00.");
        setShowToast(true);
        return;
      }


      let id = props.projetoid;


      let params: any = {
        amount: pagamentoSelecionado.valor, // Usa o valor, ou padrão 6000
        orderId: `${pagamentoSelecionado.id}`, // Usado para atualizar o pagamento gerado no front
        // external_reference: // preenche no back
        // additional_info: { //  monta no back
        //   external_reference: 
        //   print_on_terminal: true, // Habilita impressão no terminal
        // }
      };


      if (opcao === 'Mercado Pago Crédito') {
        params.type = 'credit';
      }
      else if (opcao === 'Mercado Pago Débito') {
        params.type = 'debit';

      }
      else if (opcao === 'Mercado Pago Alelo') {
        params.type = 'alelo';

      }
      else if (opcao === 'Mercado Pago Sodexo') {
        params.type = 'sodexo';
      }
      // else if (opcao === 'Mercado Pago Pix') {
      //   params.type = 'pix';
      // }
      else {
        setTextToast("Não foi possível solicitar o pagamento.");
        setShowToast(true);
        return;
      }

      requestService(apiMercadoPagoOperationCriarPagamento.url.replace("{id}", id.replace(/#/g, '%23')).replace("{idEmpresa}", dadosEmpresa.pk.replace(/#/g, '%23')).replace("{device_id}", (window.localStorage.getItem("deviceIdMercadoPago") || "")), {
        method: apiMercadoPagoOperationCriarPagamento.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        body: JSON.stringify(params),
        credentials: 'include'
      },
        history,
        (response: any) => {
          console.log(response.data);

          if (response.statusCode == 201 && response.data && response.data.id) {
            console.log("***id pagamento ml", response.data.id);

            setIdPagamentoMaquininha(response.data.id);
            setShowPagamentoMaquininha(true);

            setTextToast("Efetue o pagamento na maquininha.");
            setShowToast(true);
          }
          // else if (response.statusCode == 201 && response.data && response.data.in_store_order_id) {
          //   console.log("***id pagamento ml qrcode", response.data.in_store_order_id);

          //   setIdPagamentoMaquininha(response.data.in_store_order_id);
          //   setQrCodePagamentoMaquininha(response.data.qr_data);
          //   setShowPagamentoMaquininha(true);

          //   setTextToast("Efetue o pagamento utilizando o QrCode.");
          //   setShowToast(true);
          // }
          else {
            setTextToast("Ocorreu um erro ao processar, verifique as informações e tente novamente.");
            setShowToast(true);
          }







        },
        (error: any) => {
          console.log(error);

          if (error.status == 409) {
            setTextToast("Aguarde, pagamento em processamento.");
            setShowToast(true);
          }
          else {
            setTextToast("Ocorreu um erro ao processar, verifique as informações e tente novamente.");
            setShowToast(true);
          }
        });

    }

    consultar();
  }

  const handleRemoverFormaPagamento = (index: number) => {
    // Removendo o item pelo índice
    const formaPagamentoAtualizada = arrayFormaPagamento.filter(
      (_: any, i: any) => i !== index
    );

    // Atualizando o estado
    setArrayFormaPagamento(formaPagamentoAtualizada);
    alterarFormaPagamento(formaPagamentoAtualizada);
  };

  function calcularValorRestante(valorTotal: string, arrayFormaPagamento: any[]): any {
    const totalPagamentos = arrayFormaPagamento.reduce((total: number, campo: any) => {
      return total + parseFloat(campo.valor?.replace(/\./g, '').replace(/\,/g, '.')) || 0;
    }, 0);

    const valorTotalFloat = parseFloat(valorTotal?.replace(/\./g, '').replace(/\,/g, '.')) || 0;

    // Calcula o valor restante e garante que não seja negativo
    const valorRestante = Math.max(0, valorTotalFloat - totalPagamentos);

    // Retorna o valor formatado
    return valorRestante;
  }

  function calcularValorTroco(valorTotal: string, arrayFormaPagamento: any[]): string {
    const totalPagamentos = arrayFormaPagamento.reduce((total: number, campo: any) => {
      return total + parseFloat(campo.valor?.replace(/\./g, '').replace(/\,/g, '.')) || 0;
    }, 0);

    const valorTotalFloat = parseFloat(valorTotal?.replace(/\./g, '').replace(/\,/g, '.')) || 0;

    // Calcula o valor restante, garantindo que não seja negativo
    const valorTroco = Math.max(0, totalPagamentos - valorTotalFloat);

    // Formata o valor restante para o padrão brasileiro (duas casas decimais, vírgula e ponto)
    return valorTroco.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  }

  function calcularDivisao(valorTotal: string, divididoPor: any): string {

    const valorTotalFloat = parseFloat(valorTotal?.replace(/\./g, '').replace(/\,/g, '.')) || 0;

    const divididoPorFloat = parseFloat(divididoPor?.replace(/\./g, '').replace(/\,/g, '.')) || 0;

    console.log(valorTotalFloat / divididoPorFloat);

    // Calcula o valor restante, garantindo que não seja negativo
    const valorDividido = Math.max(0, valorTotalFloat / divididoPorFloat);

    // Formata o valor restante para o padrão brasileiro (duas casas decimais, vírgula e ponto)
    return valorDividido.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }) + " pra cada";
  }

  return (

    <>
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">

          <IonButtons slot="start">
            <IonIcon slot="icon-only" icon={arrowBack} onClick={() => {
              props.closeActionSimple(arrayFormaPagamento);
            }} />
          </IonButtons>
          <IonTitle>Fechamento</IonTitle>

        </IonToolbar>


      </IonHeader>

      <IonContent fullscreen={false}>


        <IonGrid>

          {/* <IonRow style={{ "borderBottom": "1px solid #e0e0e0" }}>
            <IonCol size="6" style={{ display: 'flex', alignItems: 'center' }}>
              <IonLabel>Taxa de Serviço:</IonLabel>
            </IonCol>
            <IonCol size="6" style={{ textAlign: 'right' }}>
              <IonSelect
                placeholder="Selecione"
                value={taxaServico}
                onIonChange={(e) => {
                  setTaxaServico(e.detail.value); // Atualiza o valor do taxaServico
                }}
              >
                {opcoesTaxaServico.map((opcao, index) => (
                  <IonSelectOption key={index} value={opcao.value}>
                    {opcao.label}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonCol>

          </IonRow>

          <IonRow style={{ "borderBottom": "1px solid #e0e0e0" }}>
            <IonCol size="6" style={{ display: 'flex', alignItems: 'center' }}>
              <IonLabel>Desconto:</IonLabel>
            </IonCol>
            <IonCol size="6" style={{ textAlign: 'right' }}>
              <IonSelect
                placeholder="Selecione"
                value={desconto}
                onIonChange={(e) => {
                  setDesconto(e.detail.value); // Atualiza o valor do desconto
                }}
              >
                {opcoesDesconto.map((opcao, index) => (
                  <IonSelectOption key={index} value={opcao.value}>
                    {opcao.label}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonCol>

          </IonRow> */}




          <IonRow>
            <IonCol size="12" style={{}}>
              <IonLabel>Forma de pagamento:</IonLabel>
            </IonCol>

          </IonRow>

          <IonRow>

            {dadosEmpresa.formaPagamento?.map((item: any, index: any) => (
              <IonCol size="auto" key={index}> {/* size="6" coloca 2 botões por linha */}
                <IonButton
                  style={{ height: "30px", fontSize: "12px", margin: "0px" }}
                  expand="block"
                  shape="round"
                  onClick={() => handleAdicionarFormaPagamento(item.nome)}
                >

                  {item.nome.startsWith("Mercado Pago") &&
                    <IonIcon
                      icon={iconMercadopago}
                      style={{
                        cursor: 'pointer',
                        color: 'red',
                        fontSize: '30px' // Ajuste para o tamanho desejado
                      }}
                    />}
                  {item.nome.toUpperCase()}
                </IonButton>
              </IonCol>
            ))}
          </IonRow>




        </IonGrid>


        <IonToast
          isOpen={showToast}
          position="middle"
          onDidDismiss={() => setShowToast(false)}
          message={textToast}
          duration={3000}
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => { setShowLoading(false); }}
          spinner='circles'
        />
      </IonContent>
      <IonFooter>

        <IonToolbar>


          <IonGrid>





            <IonRow>
              <IonCol size="5" sizeMd='4'>
                <IonLabel>Dividir a conta por:</IonLabel>
              </IonCol>
              <IonCol size="2" style={{ textAlign: 'left' }}>
                <IonInput
                  clearInput={true}
                  style={{
                    "--background": "#f5f5f5",
                    height: "35px",
                    borderRadius: "20px",
                    border: "1px solid #ccc",
                    boxSizing: "border-box",
                    fontSize: "14px",
                  }}
                  type="tel"
                  maxlength={2}
                  placeholder=""
                  value={divididoPor}
                  onIonChange={(e: any) => onChangeQuantidadeDivididoPor(e)}
                />

              </IonCol>
              <IonCol size="5" sizeMd='6' style={{ textAlign: 'right' }}>
                <IonLabel>R$ {calcularDivisao(valorTotal, divididoPor)}

                </IonLabel>
              </IonCol>
            </IonRow>







            <IonRow style={{ "borderBottom": "1px solid #e0e0e0" }}>
              <IonCol size="12" style={{
                margin: "0px",
                padding: "0px"

              }}>
                <IonGrid style={{
                  margin: "0px",
                  padding: "0px"

                }}>
                  {arrayFormaPagamento.map((campo: any, index: any) => (
                    <div key={index} style={{ marginBottom: "6px" }}>
                      <IonRow className="ion-align-items-center">

                        {/* Coluna do Label */}
                        <IonCol size="4" sizeMd="2" style={{
                          display: "flex", alignItems: "center",
                          marginTop: "0px",
                          paddingTop: "0px",
                          marginBottom: "0px",
                          paddingBottom: "0px"
                        }}>
                          <IonLabel style={{ fontSize: "14px", fontWeight: "bold" }}>
                            {campo.pagamento}:
                          </IonLabel>
                        </IonCol>

                        {/* Coluna do Input */}
                        <IonCol size="4" sizeMd="2" style={{
                          marginTop: "0px",
                          paddingTop: "0px",
                          marginBottom: "0px",
                          paddingBottom: "0px"
                        }}>
                          <IonInput disabled={campo.pagou}
                            clearInput={true}
                            style={{
                              "--background": "#f5f5f5",
                              height: "35px",
                              borderRadius: "20px",
                              border: "1px solid #ccc",
                              boxSizing: "border-box",
                              fontSize: "14px",
                            }}
                            type="tel"
                            maxlength={14}
                            placeholder="Valor"
                            value={campo.valor || ''}
                            onIonChange={(e: any) => onChangeValorArray(e, index)}
                            onIonBlur={() => salvarValor(campo.valor, index)}
                          />
                        </IonCol>

                        {/* Coluna do Botão "PAGAR" */}
                        <IonCol size="3" sizeMd="7" style={{
                          display: "flex", justifyContent: "flex-end",
                          marginTop: "0px",
                          paddingTop: "0px",
                          marginBottom: "0px",
                          paddingBottom: "0px"
                        }}>
                          {campo.pagamento.startsWith("Mercado Pago") && (
                            <IonButton disabled={campo.pagou}
                              color="success"
                              style={{
                                height: "35px",
                                fontSize: "12px",
                                marginLeft: "10px",
                                width: "100px",
                                borderRadius: "20px",
                              }}
                              expand="block"
                              onClick={() => criarPagamentoMercadoPago(index, campo.pagamento)}
                            >
                              PAGAR
                            </IonButton>
                          )}
                          {(campo.pagamento === 'InfinitePay Crédito' || campo.pagamento === 'InfinitePay Débito') && (
                            <IonButton disabled={campo.pagou}
                              color="success"
                              style={{
                                height: "35px",
                                fontSize: "12px",
                                marginLeft: "10px",
                                width: "100px",
                                borderRadius: "20px",
                              }}
                              expand="block"
                              onClick={() => criarPagamentoInfinitePay(index, campo.pagamento === 'InfinitePay Crédito' ? "credit" : "debit")}
                            >
                              PAGAR
                            </IonButton>
                          )}
                        </IonCol>

                        {/* Coluna do Ícone de Exclusão */}
                        <IonCol
                          size="1"
                          sizeMd="1"
                          style={{
                            textAlign: "right",
                            marginTop: "0px",
                            paddingTop: "0px",
                            marginBottom: "0px",
                            paddingBottom: "0px",
                          }}
                        >
                          <IonIcon
                            icon={trashOutline}
                            style={{
                              cursor: campo.pagou ? "not-allowed" : "pointer", // Cursor muda dependendo do estado
                              color: campo.pagou ? "gray" : "red",            // Cor muda dependendo do estado
                              fontSize: "20px",
                            }}
                            onClick={() => {
                              if (!campo.pagou) handleRemoverFormaPagamento(index); // Apenas chama se não estiver desabilitado
                            }}
                          />
                        </IonCol>


                      </IonRow>
                    </div>
                  ))}



                </IonGrid>

              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol size="6">
                <IonLabel>Restante:</IonLabel>
              </IonCol>
              <IonCol size="6" style={{ textAlign: 'right' }}>
                <IonLabel>R$ {calcularValorRestante(valorTotal, arrayFormaPagamento).toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}</IonLabel>
              </IonCol>
            </IonRow>


            <IonRow style={{ "borderBottom": "1px solid #e0e0e0" }}>
              <IonCol size="6">
                <IonLabel>Troco:</IonLabel>
              </IonCol>
              <IonCol size="6" style={{ textAlign: 'right' }}>

                <IonLabel>R$ {calcularValorTroco(valorTotal, arrayFormaPagamento)}

                </IonLabel>
              </IonCol>
            </IonRow>








            <IonRow>
              <IonCol size="6" style={{ fontWeight: 'bold' }}>
                <IonLabel>Subtotal:</IonLabel>
              </IonCol>
              <IonCol size="6" style={{ textAlign: 'right', fontWeight: 'bold' }}>
                <IonLabel>R$ {valorTotalProdutos || "0,00"}</IonLabel>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol size="6" style={{}}>
                <IonLabel>Desconto:</IonLabel>
              </IonCol>
              <IonCol size="6" style={{ textAlign: 'right' }}>
                <IonLabel>R$ {desconto || "0,00"}</IonLabel>
              </IonCol>
            </IonRow>

            <IonRow style={{ "borderBottom": "1px solid #e0e0e0" }}>
              <IonCol size="6" style={{}}>
                <IonLabel>Taxa de Serviço:</IonLabel>
              </IonCol>
              <IonCol size="6" style={{ textAlign: 'right' }}>
                <IonLabel>{taxaServico || "0"}%</IonLabel>
              </IonCol>
            </IonRow>



            <IonRow>
              <IonCol size="6" style={{ fontWeight: 'bold', fontSize: "20px" }}>
                <IonLabel>Valor Total:</IonLabel>
              </IonCol>
              <IonCol size="6" style={{ textAlign: 'right', fontWeight: 'bold', fontSize: "20px" }}>
                <IonLabel>R$ {valorTotal || "0,00"}</IonLabel>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol sizeMd="2" size="4">
                <IonButton color="success" style={{ height: "46px", fontSize: "18px" }} expand="block"
                  type="submit"
                  onClick={async () => {


                    // let valorRestante = calcularValorRestante(valorTotal, arrayFormaPagamento);

                    // if (valorRestante <= 0) {


                    setShowLoading(true);

                    alterar()
                      .then((response) => {
                        console.log("Alteração bem-sucedida", response);
                        return props.imprimir(); // Aguarda a execução de imprimir após alterar
                      })
                      .then(() => {
                        console.log("Impressão bem-sucedida");
                      })
                      .catch((error) => {
                        console.log("Erro:", error.message);
                        setTextToast(error.message);
                        setShowToast(true);
                      })
                      .finally(() => {
                        setShowLoading(false);
                      });
                    // }
                    // else {
                    //   setTextToast("Ainda falta R$ " + valorRestante.toLocaleString('pt-BR', {
                    //     minimumFractionDigits: 2,
                    //     maximumFractionDigits: 2
                    //   }) + " para imprimir. Selecione a forma de pagamento.");
                    //   setShowToast(true);
                    // }
                  }}
                >
                  <IonIcon icon={printOutline}></IonIcon>
                </IonButton>
              </IonCol>
              <IonCol sizeMd="10" size="8">
                <IonButton style={{ height: "46px", fontSize: "18px" }} expand="block"
                  type="submit"
                  onClick={async () => {

                    let valorRestante = calcularValorRestante(valorTotal, arrayFormaPagamento);

                    if (valorRestante <= 0) {
                      mostrarAlerta({
                        backdropDismiss: false,
                        header: 'Fechar ' + (window.localStorage.getItem("nomeInternoMesaOuComanda") || "Mesa"),
                        message: "Confirma fechamento?",
                        buttons: [{
                          text: 'Cancelar',
                          role: 'cancel'
                        },
                        {
                          text: 'Confirmar',
                          handler: async () => {


                            setShowLoading(true);

                            alterar()
                              .then((response) => {
                                console.log("Alteração bem-sucedida", response);
                                return fechar(); // Aguarda a execução de fechar após alterar
                              })
                              .then(() => {

                                console.log("dadosEmpresa.impressaoAutomatica", dadosEmpresa.impressaoAutomatica);
                                if (dadosEmpresa.impressaoAutomatica) {
                                  props.imprimir() // Aguarda a execução de imprimir após alterar
                                    .then(() => {
                                      console.log("Impressão bem-sucedida");
                                    })
                                    .catch((error: any) => {
                                      console.log("Erro ao imprimir:", error.message);
                                      setTextToast(error.message);
                                      setShowToast(true);
                                      return;
                                    })
                                }
                                props.closeAction();
                                console.log("Mesa fechada com sucesso");
                              })
                              .catch((error) => {
                                console.log("Erro:", error.message);
                                setTextToast(error.message);
                                setShowToast(true);
                              })
                              .finally(() => {
                                setShowLoading(false);
                              });


                          }
                        }],
                        onDidDismiss: () => { console.log('did dismiss'); }

                      })
                    } else {
                      setTextToast("Ainda falta R$ " + valorRestante.toLocaleString('pt-BR', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      }) + " para fechar. Selecione a forma de pagamento.");
                      setShowToast(true);
                    }

                  }}
                >
                  Fechar {(window.localStorage.getItem("nomeInternoMesaOuComanda") || "Mesa")}
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>




        </IonToolbar>
      </IonFooter>
    </>


  );
};

export default ({ projetoid, imprimir, closeAction, closeActionSimple }: { projetoid: any, imprimir: Function, closeAction: Function, closeActionSimple: Function }) => (
  <ComandasFechar projetoid={projetoid} imprimir={imprimir} closeAction={closeAction} closeActionSimple={closeActionSimple}>
  </ComandasFechar>
)
