import { IonBadge, IonButton, IonButtons, IonCol, IonContent, IonFab, IonFabButton, IonFooter, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonLoading, IonModal, IonPage, IonReorder, IonReorderGroup, IonRow, IonSelect, IonSelectOption, IonTitle, IonToast, IonToolbar, ItemReorderEventDetail, useIonAlert, useIonViewDidEnter } from '@ionic/react';
import { add, arrowBack, pencil, reorderTwo, trash } from 'ionicons/icons';
import React, { useContext, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { MessageContext } from '../../../../../contexts/MessageContext';
import { empresaAlterar, ordernarItemHorariosEmpresa, removerHorariosEmpresa, requestService } from '../../../../../Utils/Services';
import "./Horarios.css";

type Props = {
  onDismiss: () => void;
};

const Horarios: React.FC<Props> = (props: Props) => {

  const history = useHistory<any>();
  const [showLoading, setShowLoading] = useState(false);
  const [listaHorarios, setListaHorarios] = useState<any>([]);

  const [showDetalhes, setShowDetalhes] = useState(false);
  const contentRef = useRef<HTMLIonContentElement | null>(null);
  const [index, setIndex] = useState("");
  const [nome, setNome] = useState("");

  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");


  const { loja, setLoja } = useContext(MessageContext);
  const [acessos] = useState<any>(JSON.parse(window.localStorage.getItem("acessos") || '{}'));
  const { esconderMenu, setEsconderMenu } = useContext(MessageContext);
  const { nomeMenu, setNomeMenu } = useContext(MessageContext);

  const [isDisabled, setIsDisabled] = useState(true);

  const inputRef = useRef<any>(null);
  const [tipo, setHorarios] = useState('');

  const [idOpenHour, setIdOpenHour] = useState<any>();
  const [dayOfWeekOpenHour, setDayOfWeekOpenHour] = useState<any>();
  const [startOpenHour, setStartOpenHour] = useState<any>();
  const [durationOpenHour, setDurationOpenHour] = useState<any>();


  const [mostartAlerta] = useIonAlert();

  const { dadosEmpresa, setDadosEmpresa } = useContext(MessageContext);

  useIonViewDidEnter(() => {
    setEsconderMenu(true);
    setNomeMenu("");
    setListaHorarios(dadosEmpresa.horarios || []);
  });

  function deletarHorarios(indice: any) {

    var token: string = "";
    var tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") { token = tkn; }

    const delHorarios = async () => {
      const resp = await fetch(removerHorariosEmpresa.url.replace("{id}", loja.replace(/#/g, "%23")),
        {
          method: removerHorariosEmpresa.method,
          headers: [
            ["token", token]
          ],
          body: JSON.stringify({ 'index': indice }),
          credentials: 'include'
        });
      const response = await resp.json();

      if (response) {
        setListaHorarios(response.message.Attributes.horarios || []);
        dadosEmpresa.horarios = response.message.Attributes.horarios;
        setDadosEmpresa(dadosEmpresa);


        console.log("retorno", response);
        setTextToast("Horário removido com sucesso.");
        setShowToast(true);
      }
      // carregarConta();
    }

    delHorarios();

  }

  function handleReorder(event: CustomEvent<ItemReorderEventDetail>) {
    // Before complete is called with the items they will remain in the
    // order before the drag
    //console.log('Before complete', listaItemCatalogoProdutos);

    // Finish the reorder and position the item in the DOM based on
    // where the gesture ended. Update the items variable to the
    // new order of items
    setListaHorarios(event.detail.complete(listaHorarios));

    // After complete is called the items will be in the new order
    //console.log('After complete', listaItemCatalogoProdutos);


    var token: string = "";
    var tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") { token = tkn; }

    const ordItemHorarios = async () => {
      const resp = await fetch(ordernarItemHorariosEmpresa.url.replace("{id}", loja.replace(/#/g, "%23")),
        {
          method: ordernarItemHorariosEmpresa.method,
          headers: [
            ["token", token]
          ],
          body: JSON.stringify({ 'horarios': listaHorarios }),
          credentials: 'include'
        });
      const retorno = await resp.json();
      console.log("retorno ordenar", retorno);

      if (retorno && retorno.Item?.Attributes) {
        setTextToast("Ordenação alterada com sucesso.");
        setShowToast(true);
        setListaHorarios(retorno.Item?.Attributes?.horarios || []);
        dadosEmpresa.horarios = retorno.Item?.Attributes?.horarios;
        setDadosEmpresa(dadosEmpresa);

      }
      else {
        setTextToast("Ocorreu um erro ao ordernar.");
        setShowToast(true);
      }
    }

    ordItemHorarios();

  }


  const loadHorarios = () => {

    
    if (listaHorarios == undefined) return;

    if (listaHorarios.length === 0) {
      return (

        <IonItem lines="full" key={"semAgenda"}>

          <IonLabel>
            <h2 style={{ "verticalAlign": "middle" }}><strong>Nenhum horário cadastrado</strong></h2>
          </IonLabel>

        </IonItem>


      );
    }




    return (
      <>
        {/* The reorder gesture is disabled by default, enable it to drag and drop items */}
        <IonReorderGroup disabled={isDisabled} onIonItemReorder={handleReorder}>
          {listaHorarios.map((value: any, index: any) => {

            const formatDuration = (minutes: number) => {
              const hours = Math.floor(minutes / 60);
              const mins = minutes % 60;
              return `${hours} horas e ${mins} minutos`;
            };

            // Mapeamento dos dias da semana para português
            const daysOfWeekMap: { [key: string]: string } = {
              "MONDAY": "Segunda-feira",
              "TUESDAY": "Terça-feira",
              "WEDNESDAY": "Quarta-feira",
              "THURSDAY": "Quinta-feira",
              "FRIDAY": "Sexta-feira",
              "SATURDAY": "Sábado",
              "SUNDAY": "Domingo"
            };

            // Traduz o dia da semana
            const translatedDay = daysOfWeekMap[value.dayOfWeek] || value.dayOfWeek;
            return (
              <IonItem lines="full" key={index}>
                {/* <IonLabel style={{ fontSize: "12px", maxWidth: "60px" }}>
                <img id="imagem" style={{ objectFit: 'cover', width: "50px", height: "50px", borderRadius: '5px' }} src={value.urlImagem || imgDefaultImage}></img>
              </IonLabel> */}

                {/* <IonLabel style={{ fontSize: "12px" }}>{value.codigo}</IonLabel> */}
                <IonLabel style={{ fontSize: "12px" }}>{translatedDay}</IonLabel>
                <IonLabel style={{ fontSize: "12px" }}>{value.start}</IonLabel>
                <IonLabel style={{ fontSize: "12px" }}>{formatDuration(value.duration)}</IonLabel>
                <IonLabel style={{ fontSize: "12px", maxWidth: "100px" }}></IonLabel>
                <div style={{ position: "absolute", right: "10px", zIndex: 9999999, backgroundColor: "#ffffff", textAlign: "right" }}>
                  <IonReorder slot="end"></IonReorder>
                </div>

                <div style={{ position: "absolute", right: "6px", zIndex: 9999999, backgroundColor: "#ffffff", textAlign: "right" }}>
                  <IonButtons>
                    <IonButton hidden={!isDisabled || acessos?.configuracoes != 'edicao'}
                      onClick={() => {

                        setIndex(index);
                        setIdOpenHour(value.id);
                        setDayOfWeekOpenHour(value.dayOfWeek);
                        setStartOpenHour(value.start);
                        setDurationOpenHour(value.duration);



                        console.log("value", value);

                        setShowDetalhes(true);

                      }}>
                      <IonIcon color="primary" icon={pencil} />
                    </IonButton>


                    <IonButton hidden={!isDisabled || acessos?.configuracoes != 'edicao'}
                      onClick={() => {
                        mostartAlerta({
                          backdropDismiss: false,
                          header: 'Remover item',
                          message: 'Deseja <strong>remover</strong>?',
                          buttons: [
                            {
                              text: 'Cancelar',
                              role: 'cancel',
                            },
                            {
                              text: 'Confirmar',
                              cssClass: 'primary',
                              handler: () => {
                                deletarHorarios(index);
                              }
                            }
                          ],
                          onDidDismiss: (e) => console.log('did dismiss'),
                        })
                      }}>
                      <IonIcon color="primary" icon={trash} />
                    </IonButton>

                  </IonButtons>


                </div>

              </IonItem>
            )
          })}
        </IonReorderGroup>
      </>
    );
  }


  const validate = () => {
    let isValid = true;
    let errors: Array<{ message: string }> = [];

    if (!dayOfWeekOpenHour) {
      isValid = false;
      errors.push({ message: `Dia da semana não pode estar vazio.` });
    }

    if (!startOpenHour) {
      isValid = false;
      errors.push({ message: `Hora de início não pode estar vazio.` });
    }

    if (!durationOpenHour) {
      isValid = false;
      errors.push({ message: `Duração não pode estar vazio.` });
    }



    return { isValid, errors };
  };


  async function salvar() {

    const { isValid, errors } = validate();

    if (!isValid && errors.length > 0) {
      console.log('Erros de validação:', errors);

      // Mapeia o array de erros e retorna um array de strings para o IonToast
      const errorMessages: any = errors.map((error) => `${error.message}`);
      setTextToast(errorMessages.join('<br>'));
      setShowToast(true);
      return;
    }

    const objectRequest = {
      index: index,
      horarios: {
        dayOfWeek: dayOfWeekOpenHour,
        start: startOpenHour,
        duration: durationOpenHour, // Ajusta a duração para 1439 se for 1440
      }
    }
    console.log("objectRequest", objectRequest);

    let id = loja;

    console.log("id", id);

    await requestService(
      empresaAlterar.url.replace("{id}", id.replaceAll("#", "%23")).concat("?horarios=sim"),
      {
        method: empresaAlterar.method,
        body: JSON.stringify(objectRequest),
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        credentials: 'include'
      },
      history,
      (response: any) => {
        console.log(response);
        if (response) {
          setTextToast("Cadastro realizado com sucesso.");
          setShowToast(true);

          console.log("response", response);

          setListaHorarios(response.message.Attributes.horarios || []);
          dadosEmpresa.horarios = response.message.Attributes.horarios;
          setDadosEmpresa(dadosEmpresa);

          //carregarConta();
          setShowDetalhes(false);

        } else if (response.status === 400) {
          console.log(response);
          setTextToast("Ocorreu um erro ao efetuar o cadastro.");
          setShowToast(true);

        }
        setShowLoading(false);
      },
      (error: any) => {
        console.log(error);
        setTextToast("Ocorreu um erro ao efetuar o cadastro.");
        setShowToast(true);
        setShowLoading(false);
      })


  }

  const generateTimeOptions2359 = () => {
    const times = [];
    let hour = 0;
    let minute = 0;

    // Gera opções de hora no formato HH:mm:ss, de 30 em 30 minutos
    for (let i = 0; i < 48; i++) {
      const hourStr = hour < 10 ? `0${hour}` : `${hour}`;
      const minuteStr = minute < 10 ? `0${minute}` : `${minute}`;
      times.push(`${hourStr}:${minuteStr}:00`);

      // Incrementa os minutos em 30
      minute += 30;
      if (minute === 60) {
        minute = 0;
        hour += 1;
      }
    }

    // Adiciona a última opção de horário como "23:59"
    times.push("23:59:00");

    return times;
  };

  const generateDurationOptions = () => {
    const durations = [];
    let duration = 30; // Começando com 30 minutos
    while (duration <= 1439) { // Limite máximo de 1439 minutos (23h59)
      durations.push(duration);
      duration += 30; // Incrementa de 30 em 30 minutos
    }

    // Adiciona explicitamente 23:59:00
    durations.push(1439); // 1439 minutos correspondem a 23:59

    return durations;
  };


  const formatDuration = (minutes: number) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;

    // Se for 0 horas, retorna apenas os minutos
    if (hours === 0 && mins > 0) {
      return `${mins} minutos`;
    }

    // Se for 0 minutos, retorna apenas as horas
    if (mins === 0 && hours > 0) {
      return `${hours} horas`;
    }

    // Se ambos forem diferentes de 0, retorna as horas e minutos
    return `${hours} horas e ${mins} minutos`;
  };


  const handleDurationChange = (duration: number) => {
    console.log("duration", duration);
    setDurationOpenHour(duration);

    if (startOpenHour) {
      const [startHour, startMinute] = startOpenHour.split(':').map(Number);
      const startTotalMinutes = startHour * 60 + startMinute;

      const endTotalMinutes = startTotalMinutes + duration;

      // Verifica se o cálculo da duração gera um valor negativo
      console.log("startTotalMinutes", startTotalMinutes);
      console.log("endTotalMinutes", endTotalMinutes);
      // Verifica se o total de minutos ultrapassa 1440 minutos (24 horas)
      if (endTotalMinutes > 1439) {
        setDurationOpenHour(0);
        setTextToast("A duração não pode ultrapassar 23:59 horas. Verifique as informações e tente novamente.");
        setShowToast(true);
      }
    }
  };

  return (

    <IonPage className="Tipo">
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={() => { history.goBack();/*.replace("/empresa/operacoes", { recarregar: false }); */ }}>
              <IonIcon slot="icon-only" icon={arrowBack} />
            </IonButton>
          </IonButtons>
          <IonTitle>Horários</IonTitle>
        </IonToolbar>
        <IonToolbar>
          <IonList>
            <IonItem lines="none" style={{ fontWeight: 'bold', borderBottom: '1px solid #dddddd' }}>
              {/* <IonLabel style={{ fontSize: "12px", maxWidth: "60px" }}></IonLabel> */}
              <IonLabel style={{ fontSize: "12px" }}>Dia da Semana</IonLabel>
              <IonLabel style={{ fontSize: "12px" }}>Começa</IonLabel>
              <IonLabel style={{ fontSize: "12px" }}>Duração</IonLabel>

              <IonLabel style={{ textAlign: "right", maxWidth: "100px" }}>
                <IonBadge hidden={acessos?.configuracoes != 'edicao'}
                  onClick={() => setIsDisabled((current) => !current)}>
                  <IonIcon color="medium" icon={reorderTwo}></IonIcon>
                </IonBadge>
              </IonLabel>
            </IonItem>
          </IonList>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen={false}>
        <IonFab vertical="bottom" horizontal="center" slot="fixed" hidden={acessos?.configuracoes != 'edicao'}>
          <IonFabButton color="tertiary" class="custom-fab-button" onClick={() => {
            setIndex("");
            setIdOpenHour("");
            setDayOfWeekOpenHour("");
            setStartOpenHour("");
            setDurationOpenHour("");
            setShowDetalhes(true);
          }} >
            <IonIcon color="light" icon={add}></IonIcon>
          </IonFabButton>
        </IonFab>

        <IonList>
          {loadHorarios()}

        </IonList>

        <IonModal onDidPresent={() => {
          if (inputRef.current) {
            inputRef.current.setFocus();
          }
        }} isOpen={showDetalhes} onDidDismiss={() => {
          setShowDetalhes(false);
          setIndex("");
          setIdOpenHour("");
          setDayOfWeekOpenHour("");
          setStartOpenHour("");
          setDurationOpenHour("");
        }}>


          <>
            <IonHeader>
              <IonToolbar color="primary">
                <IonButtons slot="start">
                  <IonIcon slot="icon-only" icon={arrowBack} onClick={() => setShowDetalhes(false)} />
                </IonButtons>
                <IonTitle>Novo horário</IonTitle>
              </IonToolbar>
            </IonHeader>
            <IonContent>







              <IonGrid>

                <IonRow>
                  <IonCol>
                    <IonList>
                      <IonItem>
                        <IonLabel position="stacked">Dia da Semana</IonLabel>
                        <IonSelect
                          value={dayOfWeekOpenHour}
                          placeholder="Selecione um dia"
                          onIonChange={(e) => setDayOfWeekOpenHour(e.detail.value)}
                        >
                          <IonSelectOption value="MONDAY">Segunda-feira</IonSelectOption>
                          <IonSelectOption value="TUESDAY">Terça-feira</IonSelectOption>
                          <IonSelectOption value="WEDNESDAY">Quarta-feira</IonSelectOption>
                          <IonSelectOption value="THURSDAY">Quinta-feira</IonSelectOption>
                          <IonSelectOption value="FRIDAY">Sexta-feira</IonSelectOption>
                          <IonSelectOption value="SATURDAY">Sábado</IonSelectOption>
                          <IonSelectOption value="SUNDAY">Domingo</IonSelectOption>
                        </IonSelect>
                      </IonItem>


                      <IonItem>
                        <IonLabel position="stacked">Hora de Início</IonLabel>
                        <IonSelect
                          value={startOpenHour}
                          placeholder="Selecione um horário"
                          onIonChange={(e) => setStartOpenHour(e.detail.value)}
                        >
                          {generateTimeOptions2359().map((time: any, index: any) => (
                            <IonSelectOption key={index} value={time}>
                              {time}
                            </IonSelectOption>
                          ))}
                        </IonSelect>
                      </IonItem>

                      <IonItem>
                        <IonLabel position="stacked">Duração</IonLabel>
                        <IonSelect
                          value={durationOpenHour}
                          placeholder="Selecione a duração"
                          onIonChange={(e) => handleDurationChange(e.detail.value)}
                        >
                          {generateDurationOptions().map((duration: any, index: any) => (
                            <IonSelectOption key={index} value={duration}>
                              {formatDuration(duration)}
                            </IonSelectOption>
                          ))}
                        </IonSelect>
                      </IonItem>


                    </IonList>
                  </IonCol>
                </IonRow>






              </IonGrid>
            </IonContent>

            <IonFooter className="ion-no-border">
              <IonToolbar>
                <IonButton onClick={() => salvar()} expand="block" type="submit">Salvar</IonButton>
              </IonToolbar>
            </IonFooter>
          </>

        </IonModal>

        <IonToast
          isOpen={showToast}
          position="middle" //top, bottom, middle
          onDidDismiss={() => setShowToast(false)}
          message={textToast}
          duration={3000}
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => { setShowLoading(false); }}
          spinner='circles'
        />

      </IonContent>

    </IonPage>
  );


};

export default Horarios;