import { IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonLoading, IonSearchbar, IonTitle, IonToast, IonToolbar, useIonViewDidEnter } from '@ionic/react';
import { arrowBack } from 'ionicons/icons';
import * as Leaflet from 'leaflet';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { MessageContext } from '../../../../../contexts/MessageContext';
import { buscarEnderecoTaxa, requestService } from '../../../../../Utils/Services';
import './EnderecoTaxa.css';

type Props = {
  endereco: any;
  inputRef: any;
  closeAction: Function;
  closeActionSimple: Function;
}

const EnderecoTaxa: React.FC<Props> = (props: Props) => {

  const history = useHistory();
  const location = useLocation<any>();

  const { loja, setLoja } = useContext(MessageContext);

  const [enderecoSelecionado, setEnderecoSelecionado] = useState<any>();

  const [textToast, setTextToast] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const [latitudeSelecionado, setLatitudeSelecionado] = useState('');
  const [longitudeSelecionado, setLongitudeSelecionado] = useState('');

  const [taxaEntregaSelecionado, setTaxaEntregaSelecionado] = useState('');
  const [tempoEntregaSelecionado, setTempoEntregaSelecionado] = useState('');

  let mapa: any = null;
  let mark: any = null;
  // let circle: any = null;
  const [mapState, setMapState] = useState<any>(null);
  const [markState, setMarkState] = useState<any>(null);
  // const [circleState, setCircleState] = useState<any>(null);
  const [localizacaoState, setLocalizacaoState] = useState<any>(null);

  const [localEnderecoSelecionado, setLocalEnderecoSelecionado] = useState<any>("");
  const [listaEnderecos, setListaEnderecos] = useState<any>([]);

  const { dadosEmpresa, setDadosEmpresa } = useContext(MessageContext);
  const circulosRef = useRef<Leaflet.Circle[]>([]);

  const myIcon = new Leaflet.Icon({
    iconUrl: '/assets/pin.svg',
    iconSize: [30, 30],
    popupAnchor: [0, -20]
  });

  useEffect(() => {
    // Define o foco no input quando o componente é montado
    if (props.inputRef.current) {
      props.inputRef.current.focus();
    }
  }, [props.inputRef]);


  // useEffect(() => {

  //   if (props.endereco != "") {
  //     setEnderecoSelecionado(props.endereco);
  //     console.log("entrou no useEffect");
  //   }
  // }, [props.endereco]);

  useEffect(() => {
    const timer = setTimeout(() => {
      loadMap();
    }, 500); // Atraso de 1000ms

    return () => clearTimeout(timer); // Limpeza do timer
  }, []);

  useIonViewDidEnter(() => {

  })

  function cadastrar() {

    if (localizacaoState == null || !enderecoSelecionado || !latitudeSelecionado || !longitudeSelecionado) {
      setTextToast('Selecione um local para continuar.');
      setShowToast(true);
    }
    else {
      props.closeAction(enderecoSelecionado, latitudeSelecionado, longitudeSelecionado, taxaEntregaSelecionado, tempoEntregaSelecionado);
    }


  }

  function loadMap() {
    try {
      mapa = Leaflet.map('map').fitWorld();

      Leaflet.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 18,
        attribution: '© OpenStreetMap'
      }).addTo(mapa);

      mapa.on('locationfound', onLocationFound);

      mapa.on('locationerror', onLocationError);

      mapa.on('click', (ev: any) => {
        //mark.setLatLng(ev.latlng);
        //circle.setLatLng(ev.latlng);                
      });

      const localizacao = Leaflet.latLng(
        Number(dadosEmpresa.empresaLatitude),
        Number(dadosEmpresa.empresaLongitude)
      );

      let raios = [1, 2, 3, 4, 5, 8, 12];
      let cores = ['red', 'blue', 'green', 'purple', 'orange', 'pink', 'brown'];

      circulosRef.current.forEach(circle => circle.remove());
      circulosRef.current = [];

      raios.forEach((raio, index) => {
        let circle = Leaflet.circle(localizacao, {
          color: cores[index],
          fillColor: cores[index],
          fillOpacity: 0.05, // Ajusta a opacidade
          radius: raio * 1000
        }).addTo(mapa);

        circulosRef.current.push(circle);
      });

      setMapState(mapa);

      mapa.locate({ setView: true, maxZoom: 17 });
    }
    catch (e: any) {
      console.log("deu erro" + e.message);
    }
  }

  async function onLocationFound(e: any) {
    console.log("encontrou a localizacao", e.latlng);
    //let radius = 100;

    setLocalizacaoState(e.latlng);

    mark = Leaflet.marker(e.latlng, { icon: myIcon, draggable: true }).addTo(mapa);
    setMarkState(mark);



    // if (props.endereco) {
    //   let response = await buscarLocalizacaoPromise(props.endereco).catch((error) => {
    //     console.log("error.message", error.message);
    //     setTextToast(error.message);
    //     setShowToast(true);

    //   });

    //   if (response && response.Items) {

    //     console.log("response.Items", response.Items);

    //     setLatitudeSelecionado(e.latlng.lat);
    //     setLongitudeSelecionado(e.latlng.lng);
    //     setLocalizacaoState(e.latlng);

    //     mark = Leaflet.marker(e.latlng, { icon: myIcon, draggable: true }).addTo(mapa);
    //     setMarkState(mark);

    //   }
    // }

    mark.on("dragend", () => {
      //circle.setLatLng(mark.getLatLng());
      //console.log("markMatriz", mark.getLatLng());
      //setLocalizacaoState(mark.getLatLng());
    })

    mark.on("move", () => {
      const latlng = mark.getLatLng();
      setLocalizacaoState(latlng);
      setLatitudeSelecionado(latlng.lat);
      setLongitudeSelecionado(latlng.lng);
      //console.log("markMatriz", mark.getLatLng());
    })

    // circle = Leaflet.circle(e.latlng, radius).addTo(mapa);
    // setCircleState(circle);





    // **************** retirado bloco de excluir
    // markExcluir = Leaflet.marker(e.latlng, { icon: myIconExcluir, draggable: true }).addTo(mapa);
    // setMarkExcluirState(markExcluir);

    // markExcluir.on("dragend", () => {
    //     circleExcluir.setLatLng(markExcluir.getLatLng());
    //     //console.log("markExcluir", markExcluir.getLatLng());
    //     //setLocalizacaoState(mark.getLatLng());
    // })

    // markExcluir.on("move", () => {
    //     setLocalizacaoExcluirState(markExcluir.getLatLng());
    //     //console.log("markExcluir", markExcluir.getLatLng());
    // })

    // circleExcluir = Leaflet.circle(e.latlng, radius).addTo(mapa);
    // setCircleExcluirState(circleExcluir);






  }

  function onLocationError(e: any) {
  }


  function buscarLocalizacao(endereco: any) {
    if (endereco.replace(/\s/g, "") == "") {
      setTextToast('Endereço é obrigatório.');
      setShowToast(true);
      return;
    }
    // else if (numero.replace(/\s/g, "") == "") {
    //   setTextToast('Número é obrigatório.');
    //   setShowToast(true);
    //   return;
    // }



    let enderecoTratado = endereco.toLowerCase().replace(/\s/g, "+");
    console.log("enderecoTratado", enderecoTratado);

    const empresaid = loja || "";
    console.log("empresaid", empresaid);

    const urlCompleta = buscarEnderecoTaxa.url.replace("{id}", empresaid.replace(/#/g, '%23')).replace("{endereco}", enderecoTratado);
    console.log("buscarEnderecoTaxa.url", urlCompleta);

    setShowLoading(true);
    requestService(urlCompleta, {

      method: buscarEnderecoTaxa.method,
      headers: [
        ["token", window.localStorage.getItem("token")]
      ],
      credentials: 'include'
    },
      history,
      (response: any) => {
        if (response && response.Item) {
          try {
            setTextToast("Endereço localizado com sucesso.");
            setShowToast(true);

            setEnderecoSelecionado(endereco);
            setLatitudeSelecionado(response.Item.enderecoLat);
            setLongitudeSelecionado(response.Item.enderecoLng);

            setTaxaEntregaSelecionado(response.Item.taxaEntrega);
            setTempoEntregaSelecionado(response.Item.tempoEntrega);


            let novaLocalizacao = Leaflet.latLng(Number(response.Item.enderecoLat), Number(response.Item.enderecoLng));

            if (novaLocalizacao) {
              markState.setLatLng(novaLocalizacao);
              //circleState.setLatLng(novaLocalizacao);
              mapState.setView(novaLocalizacao, 14);
              setLocalizacaoState(novaLocalizacao);
            }
          } catch (error) {
            console.log("error", error);
          }

        }
      },
      (error: any) => {
        console.log("e1", error);
        // error.message.message usado quando retorna mais de um endereco
        if (!error.message.mensagemVariosEnderecos) {
          setTextToast(error.message);
          setShowToast(true);
        }
        if (error.message.data) {
          console.log("entrou varios endereços");
          if (Array.isArray(error.message.data) && error.message.data.length > 0) {
            // setExibirEndereco(true);
            setListaEnderecos(error.message.data);
          }
        }
      }).finally(() => { setShowLoading(false); });

  }



  async function buscarLocalizacaoPromise(endereco: any): Promise<any> {


    return new Promise((resolve, reject) => {

      if (endereco.replace(/\s/g, "") == "") return;

      let enderecoTratado = endereco.toLowerCase().replace(/\s/g, "+");

      setShowLoading(true);

      let token: string = "";
      const tkn = localStorage.getItem("token");
      if (typeof (tkn) === "string") {
        token = tkn;
      }

      let montarUrl = buscarEnderecoTaxa.url;



      requestService(montarUrl.replace("{id}", enderecoTratado), {
        method: "GET",
        headers: [
          ["token", token]
        ],
        credentials: 'include'
      },
        history,
        (response: any) => {
          resolve(response); // Resolvendo a promessa com a resposta
        },
        (error: any) => {
          reject(error); // Rejeitando a promessa com o erro
        });
    });
  }

  const handleSelectItem = (item: any) => {

    try {

      console.log("item", item);

      setEnderecoSelecionado(item.formatted_address);
      setLatitudeSelecionado(item.geometry.location.lat);
      setLongitudeSelecionado(item.geometry.location.lng);
      setTaxaEntregaSelecionado(item.taxaEntrega);
      setTempoEntregaSelecionado(item.tempoEntrega);

      setListaEnderecos([]);

      if (!item.taxaEntrega) {
        setTextToast("Fora da área de entrega.");
        setShowToast(true);
        return;
      }

      setTextToast("Endereço localizado com sucesso.");
      setShowToast(true);


      let novaLocalizacao = Leaflet.latLng(Number(item.geometry.location.lat), Number(item.geometry.location.lng));
      console.log("novaLocalizacao", novaLocalizacao);

      if (novaLocalizacao) {
        markState.setLatLng(novaLocalizacao);
        //circleState.setLatLng(novaLocalizacao);
        mapState.setView(novaLocalizacao, 14);
        setLocalizacaoState(novaLocalizacao);
      }
    } catch (error) {
      console.log("erro ao localizar", error);
    }

  };


  return (
    <>

      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonIcon slot="icon-only" icon={arrowBack} onClick={() => props.closeActionSimple()} />
          </IonButtons>
          <IonTitle>Endereço</IonTitle>

        </IonToolbar>
        <div style={{ background: "var(--ion-color-primary)", padding: "0px", margin: "0px" }}>
          <IonSearchbar ref={(ref) => props.inputRef.current = ref} value={enderecoSelecionado}
            onIonChange={(e) => { setEnderecoSelecionado(e.detail.value); setListaEnderecos([]); }} enterkeyhint="search"
            onKeyUp={(e: any) => {
              if (e.key === "Enter") {
                setTimeout(() => {
                  buscarLocalizacao(e.target.value);
                }, 500);
              }
            }}

            placeholder="Ex: Avenida Paulista, 111, São Paulo, SP"></IonSearchbar>
        </div>
        {listaEnderecos?.length > 0 && (
          <IonList style={{ position: 'absolute', zIndex: 999, backgroundColor: 'white', width: '100%', height: "auto", borderBottom: listaEnderecos.length > 0 ? '1px solid #f1f1f1' : 'none' }}>
            {listaEnderecos.map((item: any, index: any) => (
              <IonItem lines="none" key={"filtro" + index} button onClick={() => handleSelectItem(item)}>
                <IonLabel>{item.formatted_address}</IonLabel>
              </IonItem>
            ))}
          </IonList>
        )}

      </IonHeader>
      <IonContent className={"Mapa"}>

        {/* <div style={{ pointerEvents: "none", position: "fixed", top: 0, left: 0, width: "100%", height: "105%" }}>
          <Iframe key={"0"}
            url={"https://www.google.com/maps/embed/v1/view?key=AIzaSyDsbVscwNhxsgMynsga97Rea933LJLG5Jw&center=" + latitudeSelecionado + "," + longitudeSelecionado + "&zoom=15&maptype=roadmap"}
            width="100%"
            height="100%"
            id="myId"
            className="myClassname"
            display="inline"
            position="absolute"
            frameBorder={0}
            styles={{ border: "0" }}
          />
        </div>
        <div id="carrinho" style={{ position: "relative", width: "100%", height: "105%"}}>
          <img width={60} height={60} style={{ margin: "auto" }} src={"/assets/pin.svg"}></img>
        </div> */}
        <div style={{ position: "relative", width: "100%", height: "100%" }}>
          <div id="map" style={{ width: "100%", height: "100%" }}></div>
        </div>

        {/* <div id="map"></div> */}
      </IonContent>
      <IonFooter className="ion-no-border">
        <IonToolbar>
          <IonButton onClick={() => cadastrar()} expand="block" type="submit">Selecionar</IonButton>
        </IonToolbar>
      </IonFooter>

      <IonToast
        isOpen={showToast}
        position="middle" //top, bottom, middle
        onDidDismiss={() => setShowToast(false)}
        message={textToast}
        duration={3000}
      />


      <IonLoading
        cssClass='my-custom-class'
        isOpen={showLoading}
        onDidDismiss={() => { setShowLoading(false); }}
        spinner='circles'
      />

    </>
  );
};

export default ({ endereco, inputRef, closeAction, closeActionSimple }: { endereco: any, inputRef: any, closeAction: Function, closeActionSimple: Function }) => (
  <EnderecoTaxa endereco={endereco} inputRef={inputRef} closeAction={closeAction} closeActionSimple={closeActionSimple}>
  </EnderecoTaxa>
)
