import { IonBadge, IonButton, IonButtons, IonCol, IonContent, IonFab, IonFabButton, IonFooter, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonLoading, IonModal, IonPage, IonReorder, IonReorderGroup, IonRow, IonSelect, IonSelectOption, IonTitle, IonToast, IonToolbar, ItemReorderEventDetail, useIonAlert, useIonViewDidEnter } from '@ionic/react';
import { add, arrowBack, pencil, reorderTwo, trash } from 'ionicons/icons';
import * as Leaflet from 'leaflet';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { MessageContext } from '../../../../../contexts/MessageContext';
import { empresaAlterar, ordernarItemConfiguracoesEntregaEmpresa, removerConfiguracoesEntregaEmpresa, requestService } from '../../../../../Utils/Services';
import "./ConfiguracoesEntrega.css";

type Props = {
  onDismiss: () => void;
};

const ConfiguracoesEntrega: React.FC<Props> = (props: Props) => {

  const history = useHistory<any>();
  const [showLoading, setShowLoading] = useState(false);
  const [listaConfiguracoesEntrega, setListaConfiguracoesEntrega] = useState<any>([]);

  const [showDetalhes, setShowDetalhes] = useState(false);
  const contentRef = useRef<HTMLIonContentElement | null>(null);
  const [index, setIndex] = useState("");
  const [nome, setNome] = useState("");

  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");


  const { loja, setLoja } = useContext(MessageContext);
  const [acessos] = useState<any>(JSON.parse(window.localStorage.getItem("acessos") || '{}'));
  const { esconderMenu, setEsconderMenu } = useContext(MessageContext);
  const { nomeMenu, setNomeMenu } = useContext(MessageContext);

  const [isDisabled, setIsDisabled] = useState(true);

  const inputRef = useRef<any>(null);
  const [tipo, setConfiguracoesEntrega] = useState('');

  const [idConfiguracaoEndereco, setIdConfiguracaoEndereco] = useState<any>();
  const [raio, setRaio] = useState<any>();
  const [tempo, setTempo] = useState<any>();
  const [taxa, setTaxa] = useState<any>();


  const [mostartAlerta] = useIonAlert();

  const { dadosEmpresa, setDadosEmpresa } = useContext(MessageContext);


  const [raioSelecionado, setRaioSelecionado] = useState<number | null>(null);
  const mapaRef = useRef<Leaflet.Map | null>(null);
  const circulosRef = useRef<Leaflet.Circle[]>([]);

  const myIcon = new Leaflet.Icon({
    iconUrl: '/assets/pin.svg',
    iconSize: [30, 30],
    popupAnchor: [0, -20]
  });

  useIonViewDidEnter(() => {
    setEsconderMenu(true);
    setNomeMenu("");
    setListaConfiguracoesEntrega(dadosEmpresa.configuracoesEntrega || []);
  });

  useEffect(() => {
    if (showDetalhes) {
      setTimeout(() => {
        const container = document.getElementById('map');
        if (container) {
          loadMap();
        }
      }, 500);
    }
  }, [showDetalhes]);

  useEffect(() => {
    setTimeout(() => {
      const container = document.getElementById('map');
      if (container) {
        loadMap();
      }
    }, 500);
  }, [raioSelecionado]);

  function haversine(lat1: number, lon1: number, lat2: number, lon2: number) {
    const R = 6371; // Raio da Terra em km
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (lon2 - lon1) * (Math.PI / 180);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c; // Distância em km
  }

  function loadMap() {
    try {
      if (mapaRef.current) {
        mapaRef.current.off();  // Remove todos os eventos associados ao mapa
        mapaRef.current.remove();  // Remove a instância do mapa
        mapaRef.current = null;  // Zera a referência para evitar reutilização
      }

      const container = document.getElementById('map');
      if (container) {
        container.innerHTML = ""; // Limpa o contêiner antes de recriar o mapa
      }

      const mapa = Leaflet.map('map').setView(
        [Number(dadosEmpresa.empresaLatitude), Number(dadosEmpresa.empresaLongitude)],
        12
      );

      mapaRef.current = mapa;

      Leaflet.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 18,
        attribution: '© OpenStreetMap'
      }).addTo(mapa);

      const localizacao = Leaflet.latLng(
        Number(dadosEmpresa.empresaLatitude),
        Number(dadosEmpresa.empresaLongitude)
      );

      Leaflet.marker(localizacao, { icon: myIcon, draggable: true }).addTo(mapa);

      let raios = [1, 2, 3, 4, 5, 8, 12];
      let cores = ['red', 'blue', 'green', 'purple', 'orange', 'pink', 'brown'];

      circulosRef.current.forEach(circle => circle.remove());
      circulosRef.current = [];

      raios.forEach((raio, index) => {
        let circle = Leaflet.circle(localizacao, {
          color: cores[index],
          fillColor: cores[index],
          fillOpacity: raio === raioSelecionado ? 0.5 : 0.05,
          radius: raio * 1000
        }).addTo(mapa);

        circulosRef.current.push(circle);
      });

      mapa.on('click', (e: Leaflet.LeafletMouseEvent) => {
        const { lat, lng } = e.latlng;
        const distancia = haversine(lat, lng, localizacao.lat, localizacao.lng);

        let novoRaioSelecionado = null;

        if (distancia >= 8 && distancia <= 12) {
          novoRaioSelecionado = 12;
        } else {
          let menorDistancia = Infinity;

          raios.forEach(raio => {
            if (distancia <= raio) {
              const diferenca = Math.abs(distancia - raio);
              if (diferenca < menorDistancia) {
                menorDistancia = diferenca;
                novoRaioSelecionado = raio;
              }
            }
          });
        }

        console.log("Raio selecionado:", novoRaioSelecionado);
        setRaioSelecionado(novoRaioSelecionado);
        setRaio(novoRaioSelecionado?.toString());
      });

    } catch (e: any) {
      console.error("Erro ao carregar o mapa:", e.message);
    }
  }


  function deletarConfiguracoesEntrega(indice: any) {

    var token: string = "";
    var tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") { token = tkn; }

    const delConfiguracoesEntrega = async () => {
      const resp = await fetch(removerConfiguracoesEntregaEmpresa.url.replace("{id}", loja.replace(/#/g, "%23")),
        {
          method: removerConfiguracoesEntregaEmpresa.method,
          headers: [
            ["token", token]
          ],
          body: JSON.stringify({ 'index': indice }),
          credentials: 'include'
        });
      const response = await resp.json();

      if (response) {
        setListaConfiguracoesEntrega(response.message.Attributes.configuracoesEntrega || []);
        dadosEmpresa.configuracoesEntrega = response.message.Attributes.configuracoesEntrega;
        setDadosEmpresa(dadosEmpresa);


        console.log("retorno", response);
        setTextToast("Configuração removida com sucesso.");
        setShowToast(true);
      }
      // carregarConta();
    }

    delConfiguracoesEntrega();

  }

  function handleReorder(event: CustomEvent<ItemReorderEventDetail>) {
    // Before complete is called with the items they will remain in the
    // order before the drag
    //console.log('Before complete', listaItemCatalogoProdutos);

    // Finish the reorder and position the item in the DOM based on
    // where the gesture ended. Update the items variable to the
    // new order of items
    setListaConfiguracoesEntrega(event.detail.complete(listaConfiguracoesEntrega));

    // After complete is called the items will be in the new order
    //console.log('After complete', listaItemCatalogoProdutos);


    var token: string = "";
    var tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") { token = tkn; }

    const ordItemConfiguracoesEntrega = async () => {
      const resp = await fetch(ordernarItemConfiguracoesEntregaEmpresa.url.replace("{id}", loja.replace(/#/g, "%23")),
        {
          method: ordernarItemConfiguracoesEntregaEmpresa.method,
          headers: [
            ["token", token]
          ],
          body: JSON.stringify({ 'configuracoesEntrega': listaConfiguracoesEntrega }),
          credentials: 'include'
        });
      const retorno = await resp.json();
      console.log("retorno ordenar", retorno);

      if (retorno && retorno.Item?.Attributes) {
        setTextToast("Ordenação alterada com sucesso.");
        setShowToast(true);
        setListaConfiguracoesEntrega(retorno.Item?.Attributes?.configuracoesEntrega || []);
        dadosEmpresa.configuracoesEntrega = retorno.Item?.Attributes?.configuracoesEntrega;
        setDadosEmpresa(dadosEmpresa);

      }
      else {
        setTextToast("Ocorreu um erro ao ordernar.");
        setShowToast(true);
      }
    }

    ordItemConfiguracoesEntrega();

  }


  const loadConfiguracoesEntrega = () => {


    if (listaConfiguracoesEntrega == undefined) return;

    if (listaConfiguracoesEntrega.length === 0) {
      return (

        <IonItem lines="full" key={"semAgenda"}>

          <IonLabel>
            <h2 style={{ "verticalAlign": "middle" }}><strong>Nenhuma configuração de entrega cadastrada</strong></h2>
          </IonLabel>

        </IonItem>


      );
    }




    return (
      <>
        {/* The reorder gesture is disabled by default, enable it to drag and drop items */}
        <IonReorderGroup disabled={isDisabled} onIonItemReorder={handleReorder}>
          {listaConfiguracoesEntrega.map((value: any, index: any) => {

            return (
              <IonItem lines="full" key={index}>
                {/* <IonLabel style={{ fontSize: "12px", maxWidth: "60px" }}>
                <img id="imagem" style={{ objectFit: 'cover', width: "50px", height: "50px", borderRadius: '5px' }} src={value.urlImagem || imgDefaultImage}></img>
              </IonLabel> */}

                {/* <IonLabel style={{ fontSize: "12px" }}>{value.codigo}</IonLabel> */}
                <IonLabel style={{ fontSize: "12px" }}>{value.raio}</IonLabel>
                <IonLabel style={{ fontSize: "12px" }}>{value.tempo}</IonLabel>
                <IonLabel style={{ fontSize: "12px" }}>{value.taxa}</IonLabel>
                <IonLabel style={{ fontSize: "12px", maxWidth: "100px" }}></IonLabel>
                <div style={{ position: "absolute", right: "10px", zIndex: 9999999, backgroundColor: "#ffffff", textAlign: "right" }}>
                  <IonReorder slot="end"></IonReorder>
                </div>

                <div style={{ position: "absolute", right: "6px", zIndex: 9999999, backgroundColor: "#ffffff", textAlign: "right" }}>
                  <IonButtons>
                    <IonButton hidden={!isDisabled || acessos?.configuracoes != 'edicao'}
                      onClick={() => {

                        setIndex(index);
                        setIdConfiguracaoEndereco(value.id);
                        setRaio(value.raio);
                        setRaioSelecionado(Number(value.raio));
                        setTempo(value.tempo);
                        setTaxa(value.taxa);



                        console.log("value", value);

                        setShowDetalhes(true);

                      }}>
                      <IonIcon color="primary" icon={pencil} />
                    </IonButton>


                    <IonButton hidden={!isDisabled || acessos?.configuracoes != 'edicao'}
                      onClick={() => {
                        mostartAlerta({
                          backdropDismiss: false,
                          header: 'Remover item',
                          message: 'Deseja <strong>remover</strong>?',
                          buttons: [
                            {
                              text: 'Cancelar',
                              role: 'cancel',
                            },
                            {
                              text: 'Confirmar',
                              cssClass: 'primary',
                              handler: () => {
                                deletarConfiguracoesEntrega(index);
                              }
                            }
                          ],
                          onDidDismiss: (e) => console.log('did dismiss'),
                        })
                      }}>
                      <IonIcon color="primary" icon={trash} />
                    </IonButton>

                  </IonButtons>


                </div>

              </IonItem>
            )
          })}
        </IonReorderGroup>
      </>
    );
  }


  const validate = () => {
    let isValid = true;
    let errors: Array<{ message: string }> = [];

    if (!raio) {
      isValid = false;
      errors.push({ message: `Raio não pode estar vazio.` });
    }

    if (!tempo) {
      isValid = false;
      errors.push({ message: `Tempo não pode estar vazio.` });
    }

    if (!taxa) {
      isValid = false;
      errors.push({ message: `Taxa não pode estar vazio.` });
    }



    return { isValid, errors };
  };


  async function salvar() {

    const { isValid, errors } = validate();

    if (!isValid && errors.length > 0) {
      console.log('Erros de validação:', errors);

      // Mapeia o array de erros e retorna um array de strings para o IonToast
      const errorMessages: any = errors.map((error) => `${error.message}`);
      setTextToast(errorMessages.join('<br>'));
      setShowToast(true);
      return;
    }

    const objectRequest = {
      index: index,
      configuracoesEntrega: {
        raio: raio,
        tempo: tempo,
        taxa: taxa, // Ajusta a duração para 1439 se for 1440
      }
    }
    console.log("objectRequest", objectRequest);

    let id = loja;

    console.log("id", id);

    await requestService(
      empresaAlterar.url.replace("{id}", id.replaceAll("#", "%23")).concat("?configuracoesentrega=sim"),
      {
        method: empresaAlterar.method,
        body: JSON.stringify(objectRequest),
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        credentials: 'include'
      },
      history,
      (response: any) => {
        console.log(response);
        if (response) {
          setTextToast("Cadastro realizado com sucesso.");
          setShowToast(true);

          console.log("response", response);

          setListaConfiguracoesEntrega(response.message.Attributes.configuracoesEntrega || []);
          dadosEmpresa.configuracoesEntrega = response.message.Attributes.configuracoesEntrega;
          setDadosEmpresa(dadosEmpresa);

          //carregarConta();
          setShowDetalhes(false);

        } else if (response.status === 400) {
          console.log(response);
          setTextToast("Ocorreu um erro ao efetuar o cadastro.");
          setShowToast(true);

        }
        setShowLoading(false);
      },
      (error: any) => {
        console.log(error);
        setTextToast("Ocorreu um erro ao efetuar o cadastro.");
        setShowToast(true);
        setShowLoading(false);
      })


  }

  const generateTimeOptions5Min = () => {
    const times = [];
    for (let minute = 10; minute <= 90; minute += 5) {
      const minuteStr = minute < 10 ? `0${minute}` : `${minute}`;
      times.push(`${minuteStr}`);
    }
    return times;
  };

  const formatDuration = (minutes: number) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;

    // Se for 0 horas, retorna apenas os minutos
    if (hours === 0 && mins > 0) {
      return `${mins} minutos`;
    }

    // Se for 0 minutos, retorna apenas as horas
    if (mins === 0 && hours > 0) {
      return `${hours} horas`;
    }

    // Se ambos forem diferentes de 0, retorna as horas e minutos
    return `${hours} horas e ${mins} minutos`;
  };

  const onChangeValor = (e: any) => {
    // e.persist();
    e.preventDefault();

    let v = e.target.value;

    v = v.replace(/\d{9}/, '');
    v = v.replace(/\D/g, '');
    v = v.replace(/(\d{1})(\d{1,2})$/, "$1,$2");
    v = v.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    v = v.replace(/^(\d)/g, "$1");


    setTaxa(v);

  };

  return (

    <IonPage className="Tipo">
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={() => { history.goBack();/*.replace("/empresa/operacoes", { recarregar: false }); */ }}>
              <IonIcon slot="icon-only" icon={arrowBack} />
            </IonButton>
          </IonButtons>
          <IonTitle>Configuração de entrega</IonTitle>
        </IonToolbar>
        <IonToolbar>
          <IonList>
            <IonItem lines="none" style={{ fontWeight: 'bold', borderBottom: '1px solid #dddddd' }}>
              {/* <IonLabel style={{ fontSize: "12px", maxWidth: "60px" }}></IonLabel> */}
              <IonLabel style={{ fontSize: "12px" }}>Raio</IonLabel>
              <IonLabel style={{ fontSize: "12px" }}>Tempo(minutos)</IonLabel>
              <IonLabel style={{ fontSize: "12px" }}>Taxa(R$)</IonLabel>

              <IonLabel style={{ textAlign: "right", maxWidth: "100px" }}>
                <IonBadge hidden={acessos?.configuracoes != 'edicao'}
                  onClick={() => setIsDisabled((current) => !current)}>
                  <IonIcon color="medium" icon={reorderTwo}></IonIcon>
                </IonBadge>
              </IonLabel>
            </IonItem>
          </IonList>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen={false}>
        <IonFab vertical="bottom" horizontal="center" slot="fixed" hidden={acessos?.configuracoes != 'edicao'}>
          <IonFabButton color="tertiary" class="custom-fab-button" onClick={() => {
            setIndex("");
            setIdConfiguracaoEndereco("");
            setRaio("");
            setRaioSelecionado(null);
            setTempo("");
            setTaxa("");
            setShowDetalhes(true);
          }} >
            <IonIcon color="light" icon={add}></IonIcon>
          </IonFabButton>
        </IonFab>

        <IonList>
          {loadConfiguracoesEntrega()}

        </IonList>

        <IonModal className="fullscreen-modal" onDidPresent={() => {
          if (inputRef.current) {
            inputRef.current.setFocus();
          }
        }} isOpen={showDetalhes} onDidDismiss={() => {
          setShowDetalhes(false);
          setIndex("");
          setIdConfiguracaoEndereco("");
          setRaio("");
          setRaioSelecionado(null);
          setTempo("");
          setTaxa("");
        }}>


          <>
            <IonHeader>
              <IonToolbar color="primary">
                <IonButtons slot="start">
                  <IonIcon slot="icon-only" icon={arrowBack} onClick={() => setShowDetalhes(false)} />
                </IonButtons>
                <IonTitle>Nova Configuração</IonTitle>
              </IonToolbar>
              <IonGrid>

                <IonRow>
                  <IonCol>
                    <IonList>
                      <IonItem>
                        <IonLabel position="stacked">KM</IonLabel>
                        <IonSelect
                          value={raio}
                          placeholder="Selecione um raio"
                          onIonChange={(e) => { setRaio(e.detail.value); setRaioSelecionado(Number(e.detail.value)); }}
                        >
                          <IonSelectOption value="1">1km</IonSelectOption>
                          <IonSelectOption value="2">2km</IonSelectOption>
                          <IonSelectOption value="3">3km</IonSelectOption>
                          <IonSelectOption value="4">4km</IonSelectOption>
                          <IonSelectOption value="5">5km</IonSelectOption>
                          <IonSelectOption value="8">8km</IonSelectOption>
                          <IonSelectOption value="12">12km</IonSelectOption>
                        </IonSelect>
                      </IonItem>


                      <IonItem>
                        <IonLabel position="stacked">Tempo</IonLabel>
                        <IonSelect
                          value={tempo}
                          placeholder="Selecione o tempo"
                          onIonChange={(e) => setTempo(e.detail.value)}
                        >
                          {generateTimeOptions5Min().map((time: any, index: any) => (
                            <IonSelectOption key={index} value={time}>
                              {time} minutos
                            </IonSelectOption>
                          ))}
                        </IonSelect>
                      </IonItem>

                      <IonItem>
                        <IonLabel position="stacked">Taxa</IonLabel>

                        <IonInput
                          clearInput={true}
                          type="tel"
                          maxlength={14}
                          placeholder="Valor"
                          value={taxa || ''}
                          onIonChange={(e: any) => onChangeValor(e)}
                        />


                      </IonItem>


                    </IonList>
                  </IonCol>
                </IonRow>






              </IonGrid>
            </IonHeader>
            <IonContent className={"Mapa"}>



              <div style={{ position: "relative", width: "100%", height: "100%" }}>
                <div id="map" style={{ width: "100%", height: "100%" }}></div>
              </div>






            </IonContent>

            <IonFooter className="ion-no-border">
              <IonToolbar>
                <IonButton onClick={() => salvar()} expand="block" type="submit">Salvar</IonButton>
              </IonToolbar>
            </IonFooter>
          </>

        </IonModal>

        <IonToast
          isOpen={showToast}
          position="middle" //top, bottom, middle
          onDidDismiss={() => setShowToast(false)}
          message={textToast}
          duration={3000}
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => { setShowLoading(false); }}
          spinner='circles'
        />

      </IonContent>

    </IonPage>
  );


};

export default ConfiguracoesEntrega;